import { AddressFormType } from '../../hooks/FormContext';
import {
    addressRoleEnum,
    addressTypeEnum,
    AddressWithoutSAPNumber,
    DeliveryAddress,
    salutationEnum
} from '../../types/types';

export const getTransformedAddressData = (address: AddressFormType, email: string): DeliveryAddress =>
{
    const {
        salutation: { value: salutationValue },
        name: { value: name2 },
        surname: { value: name1 },
        street: { value: street },
        streetNumber: { value: streetNumber },
        addressCompletion: { value: street2 },
        postOffBox: { value: pfach },
        postOffBoxNoNr: { value: postOffBoxNoNr },
        zipCode: { value: pstlz },
        city: { value: ort01 },
        country: { value: land1 },
        phone: { value: isptelp }
    } = address;

    const postOffBox = !postOffBoxNoNr ? pfach : '0';

    return {
        addressType: addressTypeEnum.contractingAddress,
        addressRole: addressRoleEnum.mainAddress,
        validFrom: '',
        anred: getSalutationCode(salutationValue),
        name1: name1.trimStart(),
        name2: name2.trimStart(),
        street2: street2.trimStart(),
        land1: land1.toUpperCase(),
        pstlz: pstlz.trimStart(),
        pfach: postOffBox.trimStart(),
        ort01: ort01.trimStart(),
        isptelp: isptelp.trimStart(),
        stras: street.trimStart(),
        hausnhsnmr2: streetNumber.trimStart(),
        ispemail: email
    };
};

export const getTransformedOrderAddressData = (address: AddressWithoutSAPNumber | undefined): AddressWithoutSAPNumber | undefined =>
{
    if (address) {
        const {
            addressId,
            addressIdSf,
            ispadrbsnd,
            isphandy,
            ispteld,
            name3,
            name4,
            ortpf,
            pstl2,
            pstl3,
            titel,
            validTo,
            ...addressObject } = address;

        addressObject.validFrom = '';

        return addressObject;
    }

    return address;
};

const getSalutationCode = (salutation: string): salutationEnum =>
{
    let salutationCode: salutationEnum = salutationEnum.mr;

    switch (salutation) {
    case 'mr':
        salutationCode = salutationEnum.mr;
        break;
    case 'mrs':
        salutationCode = salutationEnum.mrs;
        break;
    default:
        salutationCode = salutationEnum.mr;
    }

    return salutationCode;
};

