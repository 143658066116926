import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@tampmd/hyperion-ui/Typography';
import { Paper } from '@tampmd/hyperion-ui/Paper';
import { AddressForm } from '../Address/components/AddressForm';
import { AddressCompany } from '../Address/components/AddressCompany';
import { StepHeader } from './components/StepHeader';
import { StepActions } from './components/StepActions';
import { DeliveryAddressStep } from './DeliveryAddressStep';
import { FormContext } from '../../hooks/FormContext';
import { CheckoutContext } from '../../hooks/CheckoutContext';
import { OfferContext } from '../../hooks/OfferContext';

export const AddressStep = (): JSX.Element =>
{
    const [ values ]: any = useContext(FormContext);
    const [ , , stepContent, setStepContent ]: any = useContext(CheckoutContext);
    const { isGiftOffer }: any = useContext(OfferContext);
    const [ isPrivate, setIsPrivate ] = useState(Boolean(true));
    const { t } = useTranslation();

    useEffect(() =>
    {
        const newStepContent = [...stepContent];

        if (values.addDeliveryAddress) {
            stepContent.length < 4 && newStepContent.splice(1, 0, <DeliveryAddressStep key="2" isGift={isGiftOffer}/>);
            setStepContent(newStepContent);
        } else if (!values.addDeliveryAddress && newStepContent.length === 4) {
            newStepContent.splice(1, 1);
            setStepContent(newStepContent);
        }
    }, [values.addDeliveryAddress]);

    return (
        <Paper classes={['content__address-step']}>
            <StepHeader title={t('address.title')} steps={isPrivate}/>
            <Paper classes={['d-flex', 'align-items-center', 'mb-3']}>
                <Typography variant={isPrivate ? 'h3' : 'text'} autoWidth inline
                    classes={[isPrivate ? '' : 'font-weight-normal']} onClick={() => setIsPrivate(true)}>
                    {t('address.private')}
                </Typography>
                &nbsp;|&nbsp;
                <Typography variant={isPrivate ? 'text' : 'h3'} autoWidth inline
                    classes={[isPrivate ? 'font-weight-normal' : '']} onClick={() => setIsPrivate(false)}>
                    {t('address.company.title')}
                </Typography>
            </Paper>
            {
                isPrivate ?
                    <AddressForm formName="address"/> :
                    <AddressCompany/>
            }
            {
                isPrivate && <StepActions/>
            }
        </Paper>
    );
};
