import { useState, useEffect, createContext, useMemo, useContext } from 'react';
import { OrderContext } from './OrderContext';
import { OfferContext } from './OfferContext';
import { BffServiceContext } from './BffServiceContext';
import { CheckoutContext } from './CheckoutContext';

interface DeliveryDateContextType {
    deliveryDate: Date | null;
    setDeliveryDate: (date: Date | null) => void;
    availableDeliveryDates: string[];
}

const DeliveryDateContext = createContext({} as DeliveryDateContextType);

const DeliveryDateProvider = ({ children }: { children: JSX.Element }): JSX.Element =>
{
    const [ deliveryDate, setDeliveryDate ] = useState<Date | null>(null);
    const [ availableDeliveryDates, setAvailableDeliveryDates ] = useState<string[]>([]);
    const { offer } = useContext(OfferContext);
    const { isOrderCreated } = useContext(OrderContext);
    const { bffService } = useContext(BffServiceContext);
    const [ step, , , , isLastStep ]: any = useContext(CheckoutContext);

    useEffect(() =>
    {
        (async function getDates()
        {
            if (bffService && offer && isOrderCreated && isLastStep) {
                const deliveryDates = (await bffService.getReleaseDates()).data.data;

                setAvailableDeliveryDates(deliveryDates);
            }
        })();
    }, [bffService, offer, isOrderCreated, step]);

    const deliveryDateValue = useMemo(() => (
        { deliveryDate, setDeliveryDate, availableDeliveryDates }
    ), [deliveryDate, availableDeliveryDates]);

    return (
        <DeliveryDateContext.Provider value={deliveryDateValue}>
            {children}
        </DeliveryDateContext.Provider>
    );
};

export {
    DeliveryDateContext,
    DeliveryDateProvider
};
