import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { DeliveryAddress, PostAddressData } from '../../types/types';

export class GatewayAddressService
{
    axiosInstance: AxiosInstance;

    constructor(idToken: string)
    {
        const baseURL = process.env.REACT_APP_GATEWAY_BASE_URL;

        this.axiosInstance = axios.create({
            baseURL,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            },
            timeout: 5000
        });
    }

    public validateAddress = async (addressData: DeliveryAddress): Promise<AxiosResponse> => {
        const payload = {
            data: {
                firstname: addressData.name1,
                lastname: addressData.name2,
                zip: addressData.pstlz,
                city: addressData.ort01,
                country: addressData.land1,
                street: addressData.stras,
                housenumber: addressData.hausnhsnmr2
            }
        };

        return await (this.axiosInstance.post('address-service/api/address/validate', payload));
    };
} 
