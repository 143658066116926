/*eslint-disable*/
export const InvoiceCard = (): JSX.Element => (
    <span style={{ display: 'inline-block', width: '2.8125rem', marginRight: '.5rem' }}>
        <svg
            id="svg8"
            version="1.1"
            viewBox="0 0 135.46667 90.205734"
            height="90.205734mm"
            width="135.46667mm"
            style={{ width: '100%', height: '30px' }}>
            <defs
                id="defs2">
                <linearGradient
                    id="linearGradient4529">
                    <stop
                        id="stop4527"
                        offset="0"
                        style={{ stopColor: '#000', stopOpacity: 1 }}/>
                </linearGradient>
            </defs>
            <g
                transform="translate(-23.358943,-89.367369)"
                id="layer1">
                <rect
                    y="92.737373"
                    x="26.014334"
                    height="83.645485"
                    width="129.17117"
                    id="rect31"
                    style={{ fill: '#fff', fillOpacity: 1, strokeWidth: 0.28039014 }}/>
                <g
                    transform="translate(-5.6696277,17.008927)"
                    id="layer1-8">
                    <g
                        transform="matrix(3.0787879,0,0,2.8189293,29.028571,55.444866)"
                        id="g3711">
                        <g
                            id="g3703">
                            <g
                                id="g3701">
                                <path
                                    style={{ fill: '#757575' }}
                                    d="M 43,6 H 1 C 0.447,6 0,6.447 0,7 v 30 c 0,0.553 0.447,1 1,1 h 42 c 0.552,0 1,-0.447 1,-1 V 7 C 44,6.447 43.552,6 43,6 Z M 42,33.581 29.612,21.194 28.198,22.608 41.59,36 H 2.41 L 15.802,22.608 14.388,21.194 2,33.581 V 8 h 40 z"
                                    id="path3699" />
                            </g>
                        </g>
                        <g
                            id="g3709">
                            <g
                                id="g3707">
                                <path
                                    style={{ fill: '#757575' }}
                                    d="M 39.979,8 22,25.979 4.021,8 H 2 V 8.807 L 21.293,28.1 c 0.391,0.391 1.023,0.391 1.414,0 L 42,8.807 V 8 Z"
                                    id="path3705" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </span>
);
