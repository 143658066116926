import { Paper } from '@tampmd/hyperion-ui/Paper';
import { Dispatch, SetStateAction } from 'react';
import { Dialog } from '@tampmd/hyperion-ui/Dialog';
import { Typography } from '@tampmd/hyperion-ui/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from '@tampmd/hyperion-ui/Button';


export interface FieldProps {
    address: any,
    toggleAddressDialog: boolean;
    setToggleAddressDialog: Dispatch<SetStateAction<boolean>>
    switchStep: any
  }

/**
 * @class
 */
export const InvalidAddressDialog = (props: FieldProps): JSX.Element =>

{
    const { t } = useTranslation();

    const closeDialog = async () => {
        if (props.toggleAddressDialog) {
            props.setToggleAddressDialog(false);
        } 
    };

    const handleOnClick = async () => {
        props.setToggleAddressDialog(false);
        await props.switchStep();
    };

    return (
        <Dialog onClose={closeDialog}>
            <Paper classes={['row', 'col-md-12']}>
                <Paper classes={['col-md-12', 'mb-3', 'mb-md-0']}>
                    <Paper classes={['d-flex', 'row', 'p-3']}>
                        <Typography variant="text">{t('address.form.addressConfirmation')}</Typography>
                        <Typography variant="button" classes={['pt-3']}>{ props.address.name.value + ' ' + props.address.surname.value + ' / ' + props.address.street.value + ' ' + props.address.streetNumber.value + ' / ' + props.address.zipCode.value + ' ' + props.address.city.value}</Typography>
                    </Paper>
                    <Paper classes={['d-flex', 'p-2', 'justify-content-center']}>
                        <Button
                            custom
                            autoWidth
                            onClick={() => handleOnClick()}
                            classes={['px-3']}
                        >
                            <Paper classes={['d-flex', 'justify-content-center']}>
                                <Typography variant="text" color="white">
                                    {t('next')}
                                </Typography>
                            </Paper>
                        </Button>
                    </Paper>
                </Paper>
            </Paper>
        </Dialog>
    );
};