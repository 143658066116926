import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './locales/i18next';
import { Checkout } from './Checkout';
import reportWebVitals from './reportWebVitals';
import { initializeDatadog } from './utils/monitoring/Datadog';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';

ReactDOM.render(
    <BrowserRouter basename="/checkout">
        <Checkout />
    </BrowserRouter>,
    document.getElementById('root')
);

reportWebVitals();

/**
 * Initializes Datadog Real User Monitoring (RUM)
 */
if (process.env.REACT_APP_DATADOG_ENV) {
    initializeDatadog();
}
