import { AddressAssembly, AllAddressesType, CustomerType, OrderType } from '../../../types/types';

export interface Customer {
    data: CustomerType;
    status: string;
}

export interface Offer {
    code: string;
    data: {
        name: string;
        description: string;
    };
    sapData: {
        offerKey: string;
        campaignKey: string;
        offerKeyGift: string;
        kavz: string;
    };
    paymentMethods: {
        code: string;
        datatransSymbol?: string;
    }[];
    bundle: {
        bundleId: string;
        data: {
            name: string;
        }
    };
    price: string;
    subscriptionTerm: string;
    studentOffer: boolean;
    type: string;
}

export interface GetOfferResponse {
    data: Offer;
    status: string;
}

export interface InitializePaymentResponse {
    data: {
        transactionId: string;
    };
    status: string;
}

export type PaymentMethodSymbol = 'VIS' | 'ECA' | 'PFC' | 'TWI';

export interface InitializePaymentPayload {
    redirect: {
        successUrl: string;
        cancelUrl: string;
        errorUrl: string;
    };
    paymentMethod?: PaymentMethodSymbol;
    orderCode?: string;
}

export interface BasicMessageResponseType {
    data: {
        message: string;
    };
    status: string;
}

export interface Order {
    data: OrderType;
    status: string;
}

export interface Address {
    data: AllAddressesType;
    status: string;
}

export interface OrderPayload {
    sapCustomerNumber: string;
    orderCode: string;
    sapOfferKey: string;
    campaignKey: string;
    kavz: string;
    paymentMethod: '8' | 'E';
    validFrom: string | null;
    giftOffer?: boolean;
    upgradeOffer?: boolean;
    subscriptionId?: string;
}

export interface OrderPrecheckResponse {
    status: string;
    data: {
        deliveryType: string;
        discount: number;
        edition: string;
        price: number;
        priceSingle: number;
        shippingCost: number;
        vat: number;
        validFrom: string;
    };
}

export type PaymentMode = 'visa' | 'master' | 'postfinance' | 'invoice';

export type OrderPaymentMode = 'E' | '8';

export const paymentModes = {
    visa: 'visa',
    master: 'master',
    postfinance: 'postfinance',
    invoice: 'invoice',
    order: {
        card: '8',
        invoice: 'E'
    }
};

export interface ReleaseDatesResponse {
    data: string[];
    status: string;
}

export interface CountriesResponse {
    status: string;
    data: {
        name: string;
        value: string;
    }[];
}

export interface AuthorizePaymentPayload {
    refno: string;
    card: {
        alias: string;
        expiryMonth: string;
        expiryYear: string;
    }
    amount: number;
    paymentMethod: string;
}

export interface GetSubscriptionsResponse {
    data: {
        active: any[];
    };
    status: string;
}

export interface GetAddressAssemblyResponse {
    data: AddressAssembly;
    status: string;
}
