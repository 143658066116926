import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Paper } from '@tampmd/hyperion-ui/Paper';
import { Typography } from '@tampmd/hyperion-ui/Typography';
import { Button } from '@tampmd/hyperion-ui/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { OfferContext } from '../../hooks/OfferContext';

export const ErrorScene = (): JSX.Element =>
{
    const { offerId } = useContext(OfferContext);
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() =>
    {
        !offerId && history.push('/not-found');
    }, []);

    return (
        <Paper id="error-page">
            <Paper classes={['error-page-content', 'd-flex']}>
                <FontAwesomeIcon icon={faExclamation} size="2x" />
                <Typography variant="h2" autoWidth classes={['mt-3 mb-5']}>
                    Oops,
                    <br/>
                    {t('errorPage.text')}
                </Typography>
                <Button autoWidth classes={['px-3']}
                    onClick={() => window.location.href = `${window.location.origin}/checkout/${offerId}`}>
                    {t('errorPage.button')}
                </Button>
            </Paper>
        </Paper>
    );
};
