import { useTranslation } from 'react-i18next';
import { Payment } from '../Payment/Payment';
import { StepHeader } from './components/StepHeader';
import { StepActions } from './components/StepActions';

export const PaymentStep = (): JSX.Element =>
{
    const { t } = useTranslation();

    return (
        <>
            <StepHeader title={t('payment.title')}/>
            <Payment/>
            <StepActions/>
        </>
    );
};
