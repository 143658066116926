import { TenantService } from '@tampmd/utils/Tenant';
import { TenantType } from '../../types/types';

const getLocalTenantID = (tenantID: string) =>
{
    let localTenantID = tenantID;

    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEFAULT_TENANT) {
        localTenantID = process.env.REACT_APP_DEFAULT_TENANT;
    }

    return localTenantID;
};

export const getOne = async (tenantID: string): Promise<TenantType> =>
{
    const tenantService = await TenantService.create(
        getLocalTenantID(tenantID), process.env.REACT_APP_TENANT_API_BASE_URL
    );

    return {
        id: tenantService.getId(),
        name: tenantService.getName(),
        language: tenantService.getLanguage(),
        domainName: tenantService.getDomainName(),
        contact: {
            onlineForm: tenantService.getContactFormUrl(),
            phone: tenantService.getPhone()
        },
        color: {
            primary: tenantService.getPrimaryColor(),
            secondary: tenantService.getSecondaryColor()
        },
        favicon: tenantService.getFavicon(),
        logo: tenantService.getDesktopLogo(),
        smallLogo: tenantService.getMobileLogo(),
        links: tenantService.getLinks(),
        ...(tenantService.getMobileApps() && {
            mobileApps: {
                url: tenantService.getMobileApps().url,
                qrCodeImage: tenantService.getMobileApps().qrCodeImage
            }
        })
    };
};
