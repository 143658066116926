import { createContext, useState, useMemo, useEffect, useContext, Dispatch, SetStateAction } from 'react';
import { BffServiceContext } from './BffServiceContext';
import { CustomerContext } from './CustomerContext';
import { OrderContext } from './OrderContext';
import { OfferContext } from './OfferContext';
import { CheckoutContext } from './CheckoutContext';
import { addressNameEnum, salutationEnum, AddressAssembly } from '../types/types';

export type FieldType = {
    value: any;
    isRequired: boolean;
}

export type AddressFormType = {
    salutation: FieldType;
    name: FieldType;
    surname: FieldType;
    street: FieldType;
    streetNumber: FieldType;
    addressCompletion: FieldType;
    postOffBox: FieldType;
    postOffBoxNoNr: FieldType;
    zipCode: FieldType;
    city: FieldType;
    country: FieldType;
    phone: FieldType;
    assembly?: AddressAssembly;
}

interface Values {
    address: AddressFormType;
    deliveryAddress: AddressFormType;
    addDeliveryAddress: boolean;
    isLoading: boolean;
}

export interface Country {
    name: string;
    value: string;
}

const FormContext = createContext({});

FormContext.displayName = 'Form';

const FormProvider = ({ children }: { children: JSX.Element }): JSX.Element =>
{
    const [ values, setValues ]: [Values, Dispatch<SetStateAction<Values>>] = useState<Values>({
        address: {
            salutation: { value: '', isRequired: true },
            name: { value: '', isRequired: true },
            surname: { value: '', isRequired: true },
            street: { value: '', isRequired: true },
            streetNumber: { value: '', isRequired: false },
            addressCompletion: { value: '', isRequired: false },
            postOffBox: { value: '', isRequired: false },
            postOffBoxNoNr: { value: false, isRequired: false },
            zipCode: { value: '', isRequired: true },
            city: { value: '', isRequired: true },
            country: { value: '', isRequired: true },
            phone: { value: '', isRequired: false }
        },
        deliveryAddress: {
            salutation: { value: '', isRequired: true },
            name: { value: '', isRequired: true },
            surname: { value: '', isRequired: true },
            street: { value: '', isRequired: true },
            streetNumber: { value: '', isRequired: false },
            addressCompletion: { value: '', isRequired: false },
            postOffBox: { value: '', isRequired: false },
            postOffBoxNoNr: { value: false, isRequired: false },
            zipCode: { value: '', isRequired: true },
            city: { value: '', isRequired: true },
            country: { value: '', isRequired: true },
            phone: { value: '', isRequired: false }
        },
        addDeliveryAddress: false,
        isLoading: false
    });
    const [ countries, setCountries ]: [Country[], any] = useState([]);
    const [ isAddressUpdated, setIsAddressUpdated ]: [boolean, any] = useState(true);
    const { isCustomerLinked } = useContext(CustomerContext);
    const { bffService } = useContext(BffServiceContext);
    const { offer, isGiftOffer } = useContext(OfferContext);
    const { order, isOrderCreated } = useContext(OrderContext);
    const [ step, , , , isLastStep ]: any = useContext(CheckoutContext);

    useEffect(() =>
    {
        (async function getCountriesData()
        {
            if (bffService && !countries.length && offer && isOrderCreated) {
                const { data: { data }} = await bffService.getCountries();

                setCountries(data);

                if (!isCustomerLinked) {
                    setValues({
                        ...values,
                        address: {
                            ...values.address,
                            country: {
                                ...values.address.country,
                                value: 'CH'
                            }
                        }
                    });
                }
            }
        })();
    }, [bffService, offer, isOrderCreated]);

    useEffect(() =>
    {
        (async function getData()
        {
            if (bffService && offer && isOrderCreated && isCustomerLinked && isAddressUpdated) {
                setValues({
                    ...values,
                    isLoading: true
                });

                const { data: { data: { assembly, addresses: [{
                    addressId, anred, name2, name1, hausnhsnmr2, stras, street2, pfach, pstlz, ort01, land1, isptelp
                }] }}} = await bffService.getAddress(
                    addressNameEnum.contractingAddress
                );
                const { deliveryAddress } = order;
                const postOffBoxValue = (Boolean(pfach) && pfach !== '0') ? pfach : '';
                const postOffBoxValueNoNr = pfach === '0' ? true : '';
                const deliveryAddressPfach = deliveryAddress?.pfach;
                const deliveryAddressPostOffBoxValue =
                    (Boolean(deliveryAddressPfach) && deliveryAddressPfach !== '0') ? deliveryAddressPfach : '';
                const deliveryAddressPostOffBoxValueNoNr = deliveryAddressPfach === '0' ? true : '';
                const isDifferentDeliveryAddress = deliveryAddress && (addressId !== deliveryAddress.addressId);

                setValues({
                    ...values,
                    address: {
                        salutation: {
                            ...values.address.salutation,
                            value: anred === salutationEnum.mr ? 'mr' : 'mrs'
                        },
                        name: { ...values.address.name, value: name2 },
                        surname: { ...values.address.surname, value: name1 },
                        street: { ...values.address.street, value: stras },
                        streetNumber: { ...values.address.streetNumber, value: hausnhsnmr2 },
                        addressCompletion: { ...values.address.addressCompletion, value: street2 || '' },
                        postOffBox: { ...values.address.postOffBox, value: postOffBoxValue },
                        postOffBoxNoNr: { ...values.address.postOffBoxNoNr, value: postOffBoxValueNoNr },
                        zipCode: { ...values.address.zipCode, value: pstlz },
                        city: { ...values.address.city, value: ort01 },
                        country: { ...values.address.country, value: land1 },
                        phone: { ...values.address.phone, value: isptelp },
                        assembly
                    },
                    ...((deliveryAddress && (!isGiftOffer || (isGiftOffer && isDifferentDeliveryAddress))) && {
                        deliveryAddress: {
                            salutation: {
                                ...values.deliveryAddress.salutation,
                                value: deliveryAddress.anred === salutationEnum.mr ? 'mr' : 'mrs'
                            },
                            name: { ...values.deliveryAddress.name, value: deliveryAddress.name2 },
                            surname: { ...values.deliveryAddress.surname, value: deliveryAddress.name1 },
                            street: { ...values.deliveryAddress.street, value: deliveryAddress.stras },
                            streetNumber: {
                                ...values.deliveryAddress.streetNumber, value: deliveryAddress.hausnhsnmr2
                            },
                            addressCompletion: {
                                ...values.deliveryAddress.addressCompletion, value: deliveryAddress.street2 || '' },
                            postOffBox: { ...values.deliveryAddress.postOffBox, value: deliveryAddressPostOffBoxValue },
                            postOffBoxNoNr: { ...values.deliveryAddress.postOffBoxNoNr, value: deliveryAddressPostOffBoxValueNoNr },
                            zipCode: { ...values.deliveryAddress.zipCode, value: deliveryAddress.pstlz },
                            city: { ...values.deliveryAddress.city, value: deliveryAddress.ort01 },
                            country: { ...values.deliveryAddress.country, value: deliveryAddress.land1 },
                            phone: { ...values.deliveryAddress.phone, value: deliveryAddress.isptelp }
                        }
                    }),
                    addDeliveryAddress: isGiftOffer || isDifferentDeliveryAddress || values.addDeliveryAddress,
                    isLoading: false
                });
                setIsAddressUpdated(false);
            } else if (!isCustomerLinked) {
                setValues({
                    ...values,
                    addDeliveryAddress: isGiftOffer
                });
            }
        })();
    }, [offer, isOrderCreated, bffService, isCustomerLinked, isAddressUpdated]);

    useEffect(() => {
        (async function getDeliveryAddressAssembly () {
            if (isLastStep && values.addDeliveryAddress && !values.isLoading && bffService) {
                const { deliveryAddress: { _id, ...orderDeliveryAddress }} = order as any;
                const { data: { data: assembly }} = await bffService.getAddressAssembly(orderDeliveryAddress);

                setValues({
                    ...values,
                    deliveryAddress: {
                        ...values.deliveryAddress,
                        assembly
                    }
                });
            }
        })();
    }, [bffService, step, values.isLoading]);

    const updateValue = ({ form, name, value }: { form: string | null; name: string; value: any }) =>
    {
        if (form) {
            setValues({
                ...values,
                [form]: {
                    ...(values as any)[form],
                    [name]: {
                        ...(values as any)[form][name],
                        value
                    }
                }
            });
        } else {
            setValues({
                ...values,
                [name]: value
            });
        }
    };

    const formValues = useMemo(() => ([values, updateValue, countries, setIsAddressUpdated]), [values, countries, order]);

    return (
        <FormContext.Provider value={formValues}>
            {children}
        </FormContext.Provider>
    );
};

export { FormContext, FormProvider };
