import { useContext } from 'react';
import { Typography } from '@tampmd/hyperion-ui/Typography';
import { Paper } from '@tampmd/hyperion-ui/Paper';
import { CheckoutContext } from '../../../hooks/CheckoutContext';

export const StepHeader = ({ title, steps = true }: { title: string; steps?: boolean }): JSX.Element =>
{
    const [ step, , stepContent ]: any = useContext(CheckoutContext);

    return (
        <Paper classes={['d-flex', 'justify-content-between', 'align-items-center', 'my-4']}>
            <Typography variant="h1" autoWidth inline>{title}</Typography>
            {
                (steps && step < stepContent.length) &&
                <Typography variant="h3" autoWidth inline>
                    {step}/{stepContent.length}
                </Typography>
            }
        </Paper>
    );
};
