import { useState, createContext, useEffect, useContext } from 'react';
import axios from 'axios';
import { TenantContext } from './TenantContext';
import { AuthenticationContext } from './AuthenticationContext';
import { BffService } from '../services/apis/Bff/BffService';
import { RedirectionService } from '../services/utils/Redirection/Redirection';

type BffServiceContextType = {
    bffService: BffService | null;
}

export const BffServiceContext = createContext<BffServiceContextType>({} as BffServiceContextType);

BffServiceContext.displayName = 'BffService';

export const BffServiceProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
    const [bffService, setBffService]: [BffService | null, any] = useState(null);
    const { tenantState: [tenant] }: any = useContext(TenantContext);
    const { auth, setAuth } = useContext(AuthenticationContext);

    useEffect(() => {
        if (tenant.id && auth && !bffService) {
            const bffService = new BffService(tenant.id);

            const setRequestInterceptor =
                (bffService: BffService, idToken: string) => bffService.axiosInstance.interceptors.request.use(
                    config => {
                        if (config?.headers) {
                            config.headers['Authorization'] = `Bearer ${idToken}`;
                        }

                        return config;
                    },
                    error => Promise.reject(error)
                );

            let failedRequests: any[] = [];
            let isRefreshingIdToken = false;

            const addFailedRequest = (failedRequest: any) => {
                failedRequests.push(failedRequest);
            };

            const onIdTokenFetched = (idToken: string) => {
                failedRequests = failedRequests.filter(failedRequest => failedRequest(idToken));
            };

            const getIdToken = async (): Promise<string> => {
                const redirection = new RedirectionService(tenant.id);

                return await redirection.getToken();
            };

            const requestInterceptor = setRequestInterceptor(bffService, auth);

            bffService.axiosInstance.interceptors.response.use(response => response, async error => {
                const { response: { status: errorStatus }, config: originalRequestConfig } = error;

                if (errorStatus === 401) {
                    const retryOriginalRequest = new Promise(resolve => {
                        addFailedRequest((idToken: string) => {
                            originalRequestConfig.headers.Authorization = `Bearer ${idToken}`;
                            resolve(axios(originalRequestConfig));
                        });
                    });

                    if (!isRefreshingIdToken) {
                        isRefreshingIdToken = true;
                        const idToken = await getIdToken();

                        setAuth(idToken);
                        isRefreshingIdToken = false;
                        bffService.axiosInstance.interceptors.request.eject(requestInterceptor);
                        setRequestInterceptor(bffService, idToken);
                        onIdTokenFetched(idToken);
                    }

                    return retryOriginalRequest;
                } else if (String(error.response.status).match(/^5[0-9]{2}$/g)) {
                    error.response.data = {
                        error: {
                            message: 'Internal server error. Please contact your administrator!'
                        }
                    };
                }

                return Promise.reject(error);
            });

            setBffService(bffService);
        }
    }, [tenant.id, auth]);

    return (
        <BffServiceContext.Provider value={{ bffService }} >
            {children}
        </BffServiceContext.Provider>
    );
};
