import { datadogRum } from '@datadog/browser-rum';

const env = process.env.REACT_APP_DATADOG_ENV || '';
const version = process.env.REACT_APP_VERSION || '';

const initialize = (): void => {
    datadogRum.init({
        applicationId: 'bd0351a3-0ed9-46a8-8245-1bb0f2af6f7f',
        clientToken: 'pubf2f5063f964dd39d3df32cf6222bbc3f',
        site: 'datadoghq.eu',
        service: 'titan---checkout',
        env: env,
        version: version,
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input'
    });

    datadogRum.startSessionReplayRecording();
};

export default initialize;
