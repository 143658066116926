import { useContext } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { TenantContext } from '../../hooks/TenantContext';
import { cmpIds } from '../../config/cmpConfig/cmpConfigIds';
export const Head = (): JSX.Element =>
{
    const {
        tenantState: [ tenant ]
    }: any = useContext(TenantContext);

    const cmpId = cmpIds.find(entry => entry.tenant === tenant.id)?.oneTrustCmpId;
    
    return (
        <HelmetProvider>
            <Helmet>
                <title>{tenant.name}</title>
                <html lang={tenant.language} />
                <link rel="icon" href={tenant.favicon} />
                <script
                    src={`https://cdn.cookielaw.org/consent/${cmpId}/otSDKStub.js`}
                    data-document-language="true"
                    type="text/javascript"
                    data-domain-script={cmpId}
                />
                <script>
                    {`function OptanonWrapper() {
                        // GTM integration
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({event: 'OneTrustGroupsUpdated'});
                        // Sync with React context
                        var event = new CustomEvent('consentChange');
                        window.dispatchEvent(event);
                    }`}
                </script>
                <script>
                    {`//SHOULD BE USED ONLY ON COOKIE POLICY. TRIGGER FUNCTION BELOW TO REMOVE DUPLICATE CATEGORIES
                        function clearDup() {
                            var sec = document.getElementById("ot-sdk-cookie-policy")
                            var tally = [];
                            for (var i = sec.length - 1; i >= 0; i--) {
                                if (tally[sec[i].firstChild.innerText] === undefined) {
                                    tally[sec[i].firstChild.innerText] = 1;
                                } else {
                                    //console.log(i,sec[i].firstChild.innerText);
                                    sec[i].remove();
                                    //return true;
                                }
                            }
                            //return false;
                    }`}
                </script>
            </Helmet>
        </HelmetProvider>
    );
};
