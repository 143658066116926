import { createContext, useState, useEffect, useMemo, Dispatch, SetStateAction, ReactNode } from 'react';
import { DomainService } from '@tampmd/utils/Domain';
import { i18n } from '../locales/i18next';
import { getOne } from '../services/Tenant/Tenant';
import { TenantType } from '../types/types';

const TenantContext = createContext({});

TenantContext.displayName = 'Tenant';

const initialState: TenantType = {
    id: '',
    name: '',
    color: {
        primary: '',
        secondary: ''
    },
    language: 'de',
    domainName: '',
    contact: {
        onlineForm: '',
        phone: ''
    },
    favicon: '',
    logo: '',
    smallLogo: '',
    links: {
        faq: '',
        contact: '',
        termsAndConditions: '',
        impressum: '',
        social: {
            facebook: '',
            instagram: '',
            linkedIn: '',
            twitter: '',
            youtube: ''
        },
        dataProtection: '',
        pianoLandingPage: ''
    }
};

const TenantProvider = ({ children }: { children: ReactNode }): JSX.Element =>
{
    const [tenant, setTenant]: [TenantType, Dispatch<SetStateAction<TenantType>>] = useState(initialState);
    const [isMobile, setIsMobile]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(Boolean);
    const [isLoaded, setIsLoaded]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(Boolean);

    useEffect(() =>
    {
        (async function getTenant()
        {
            const domainService = new DomainService();
            const tenant = await getOne(domainService.getDomain());

            setTenant(tenant);
            await i18n.changeLanguage(tenant.language);
            setIsLoaded(true);
        })();
    }, []);

    const tenantValue = useMemo(() => (
        {
            tenantState: [tenant, setTenant],
            responsiveState: [isMobile, setIsMobile],
            dataState: [isLoaded, setIsLoaded]
        }
    ), [tenant, isMobile, isLoaded]);

    return (
        <TenantContext.Provider value={tenantValue}>
            {children}
        </TenantContext.Provider>
    );
};

export { TenantContext, TenantProvider };
