import React, { createContext, useState, useMemo, Dispatch, SetStateAction } from 'react';

interface PopupType {
    isOpen?: boolean;
    content?: JSX.Element;
}

const PopupContext = createContext({});

PopupContext.displayName = 'Popup';

const PopupProvider = ({ children }: { children: JSX.Element }): JSX.Element =>
{
    const [popup, setPopup]: [PopupType, Dispatch<SetStateAction<PopupType>>] = useState({});
    const popupValue = useMemo(() => ([popup, setPopup]), [popup]);

    return (
        <PopupContext.Provider value={popupValue}>
            {children}
        </PopupContext.Provider>
    );
};

export { PopupContext, PopupProvider };
