import { createContext, useState, useMemo, Dispatch, SetStateAction } from 'react';
import { NotificationType } from '../types/types';

const NotificationContext = createContext<{
    notification: NotificationType,
    setNotification: Dispatch<SetStateAction<NotificationType>>
}>({
    notification: { description: '' },
    setNotification: () => ('')
});

const NotificationProvider = ({ children }: { children: JSX.Element }): JSX.Element =>
{
    const [notification, setNotification]: [NotificationType, Dispatch<SetStateAction<NotificationType>>] = useState({
        description: ''
    });

    const notificationValue = useMemo(() => (
        { notification, setNotification }
    ), [notification]);

    return (
        <NotificationContext.Provider value={notificationValue}>
            {children}
        </NotificationContext.Provider>
    );
};

export {
    NotificationContext,
    NotificationProvider
};
