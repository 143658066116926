import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@tampmd/hyperion-ui/Button';
import { DatePicker } from '@tampmd/hyperion-ui/DatePicker';
import { Divider } from '@tampmd/hyperion-ui/Divider';
import { Paper } from '@tampmd/hyperion-ui/Paper';
import { Typography } from '@tampmd/hyperion-ui/Typography';
import momentJs from 'moment';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useParams } from 'react-router-dom';
import { BffServiceContext } from '../../hooks/BffServiceContext';
import { CustomerContext } from '../../hooks/CustomerContext';
import { DeliveryDateContext } from '../../hooks/DeliveryDateContext';
import { FormContext } from '../../hooks/FormContext';
import { NotificationContext } from '../../hooks/NotificationContext';
import { OfferContext } from '../../hooks/OfferContext';
import { OrderContext } from '../../hooks/OrderContext';
import { PaymentContext } from '../../hooks/PaymentContext';
import { TenantContext } from '../../hooks/TenantContext';
import { OrderPaymentMode, paymentModes } from '../../services/apis/Bff/types';

const Intro = (): JSX.Element =>
{
    const { offer } = useContext(OfferContext);

    return (
        <>
            <Typography variant="h3" classes={['mt-4']}>{offer?.data.name}</Typography>
            <Typography variant="text" classes={['mt-2']}>{offer?.data.description}</Typography>
        </>
    );
};

const Total = (): JSX.Element =>
{
    const { order } = useContext(OrderContext);
    const { t } = useTranslation();

    return (
        <>
            <Paper classes={['summary__total-price', 'mt-5', 'mb-4']}>
                <Paper>
                    <Typography variant="h2" classes={['text-left']}>{t('summary.total')}</Typography>
                    {
                        order?.precheck &&
                        <>
                            {
                                order.precheck.discount !== 0 &&
                                <Typography variant="small">{t('summary.discount')}</Typography>
                            }
                            {
                                order.precheck.shippingCost !== 0 &&
                                <Typography variant="small">{t('summary.shipping')}</Typography>
                            }
                        </>
                    }
                </Paper>
                {
                    order?.precheck ?
                        <Paper>
                            <Typography variant="h2" align="right">
                                {`${order.precheck.price.toFixed(2)} CHF`}
                            </Typography>
                            {
                                (order.precheck.discount !== 0) &&
                                <Typography variant="small" align="right">
                                    {`${order.precheck.discount.toFixed(2)} CHF`}
                                </Typography>
                            }
                            {
                                (order.precheck.shippingCost !== 0) &&
                                <Typography variant="small" align="right">
                                    {`${order.precheck.shippingCost.toFixed(2)} CHF`}
                                </Typography>
                            }
                        </Paper> :
                        <Skeleton count={1} width={'125px'} height={'30px'} />
                }
            </Paper>
            <Divider/>
        </>
    );
};

const TermsAndConditions = (): JSX.Element =>
{
    const { t } = useTranslation();
    const { tenantState: [ tenant ] }: any = useContext(TenantContext);
    const { links: { dataProtection, termsAndConditions }} = tenant;

    return (
        <Typography variant="text" classes={['my-4', 'terms']}>
            {t('summary.accept.partOne')}
            <a href={termsAndConditions} target="_blank" rel="noreferrer">{` ${t('summary.accept.t&c')} `}</a>
            {t('summary.accept.and1')}
            <a href={dataProtection} target="_blank" rel="noreferrer">{` ${t('summary.accept.dataProtection')}`}</a>
            {t('summary.accept.and2') && ` ${t('summary.accept.and2')}`}.
        </Typography>
    );
};

const DeliveryDateAndSubmit = (): JSX.Element =>
{
    const { tenantState: [ tenant ] }: any = useContext(TenantContext);
    const { language } = tenant;
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ values ]: any = useContext(FormContext);
    const { bffService } = useContext(BffServiceContext);
    const { setNotification } = useContext(NotificationContext);
    const { customer } = useContext(CustomerContext);
    const { order, setIsOrderCompleted, setIsPostprocessing, setTransactionCode } = useContext(OrderContext);
    const { offer, isGiftOffer, isUpgradeOffer, alternativeKavz, validFrom } = useContext(OfferContext);
    const { deliveryDate, availableDeliveryDates, setDeliveryDate } = useContext(DeliveryDateContext);
    const { payment } = useContext(PaymentContext);
    const { t } = useTranslation();
    const history = useHistory();
    const { subscriptionId }: { subscriptionId: string } = useParams();

    const createOrder = async () =>
    {
        try {
            if (bffService && offer && order.code && order.precheck) {
                const paymentMethod = (payment === paymentModes.invoice ?
                    paymentModes.order.invoice :
                    paymentModes.order.card) as OrderPaymentMode;

                setIsLoading(true);

                if (payment !== paymentModes.invoice && customer.payment) {
                    const { data: { data: { transactionId }}} = await bffService.authorizePayment({
                        refno: String(order.code),
                        amount: Number(order.precheck.price),
                        card: {
                            alias: customer.payment.ccAlias,
                            expiryMonth: customer.payment.validToMonth,
                            expiryYear: customer.payment.validToYear
                        },
                        paymentMethod: customer.payment.type
                    });

                    setTransactionCode(transactionId);
                }

                const { data: { data }} = await bffService.createOrder({
                    sapCustomerNumber: customer.sapId,
                    orderCode: String(order.code),
                    sapOfferKey: isGiftOffer ? offer.sapData.offerKeyGift : offer.sapData.offerKey,
                    campaignKey: offer.sapData.campaignKey,
                    kavz: alternativeKavz || offer.sapData.kavz,
                    paymentMethod,
                    validFrom: momentJs(deliveryDate).format('yyyy-MM-DD'),
                    giftOffer: isGiftOffer,
                    upgradeOffer: isUpgradeOffer,
                    subscriptionId: subscriptionId || ''
                });

                data.postProccessing && setIsPostprocessing(true);
                setIsOrderCompleted(true);
                history.push('/thank-you');
            }
        } catch (error: any) {
            const { response: { data: errorResponse }} = error;
            const { message } = errorResponse.error || errorResponse.data;

            setNotification({
                status: 'danger',
                description: message
            });
        } finally {
            setIsLoading(false);
        }
    };

    const isSubmitDisabled = (): boolean => (
        !values.address.assembly || !order.precheck || !deliveryDate || isLoading
    );
    const minUpgradeDate = availableDeliveryDates
        .filter(date => momentJs(date).unix() - momentJs(validFrom).unix() > 0)[0];

    return (
        <>
            <Divider/>
            <Paper classes={['row', 'w-100', 'mt-4', 'mx-0', 'd-flex', 'align-items-center']}>
                <Typography variant="h3" classes={['mb-2']}>{t('summary.deliveryDate')}*</Typography>
                <Paper classes={['col-12', 'col-sm-6', 'px-0', 'pr-sm-3', 'mb-3', 'mb-sm-0']}>
                    <DatePicker
                        onChange={value => setDeliveryDate(value)}
                        selected={deliveryDate}
                        placeholder={t('summary.selectDate')}
                        minDate={subscriptionId ? new Date(minUpgradeDate) : new Date(availableDeliveryDates[0])}
                        includeDates={availableDeliveryDates.map((date: string) => new Date(date))}
                        dateFormat={'dd.MM.yyyy'}
                        locale={language}
                    />
                </Paper>
                <Paper classes={['col-12', 'col-sm-6', 'px-0', 'd-flex', 'justify-content-end']}>
                    <Button classes={['summary__submit-btn', 'px-3']}
                        custom
                        autoWidth
                        disabled={isSubmitDisabled()}
                        onClick={createOrder}>
                        <Paper classes={['d-flex', 'align-items-center', 'justify-content-center']}>
                            <Typography variant="text" color={isSubmitDisabled() ? 'gray' : 'white'}>
                                {t('summary.submit')}
                            </Typography>
                            {isLoading && <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin ml-2"/>}
                        </Paper>
                    </Button>
                </Paper>
            </Paper>
        </>
    );
};

export const Summary = (): JSX.Element => (
    <Paper classes={['content__summary']}>
        <Intro />
        <Total/>
        <TermsAndConditions/>
        <DeliveryDateAndSubmit/>
    </Paper>
);
