import { useState, useEffect, createContext, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { BffServiceContext } from './BffServiceContext';
import { TenantContext } from './TenantContext';
import { Offer } from '../services/apis/Bff/types';
import { getGiftTranslationsRegex, getCampaginTranslationsRegex } from '../routes/Routes';

export type OfferType = Offer | null;

export type OfferContextType = {
    offer: OfferType | null;
    setOfferId: () => void;
    offerId: string;
    isGiftOffer: boolean;
    isUpgradeOffer: boolean;
    isCampagin: boolean;
    alternativeKavz: string | null;
    validFrom: string;
};

const isUpgradeSubscription = (subscriptionId: string, subs: any[]): boolean => {
    let isUpgradeSubscription = false;

    if (subs.length > 0) {
        const subscription = subs.filter((subscription: any) => (
            (subscription.subscriptionId === subscriptionId) && (subscription.subscriptionType === 'ZABO')
        ));

        isUpgradeSubscription = subscription.length > 0;
    }

    return isUpgradeSubscription;
};

const getUpgradeValidFrom = (subscriptionId: string, subs: any[]): string => {
    let validFrom = '';

    if (subs.length > 0) {
        const subscription = subs.find(({ subscriptionId: subId }) => subId === subscriptionId);

        validFrom = subscription?.validFrom;
    }

    return validFrom;
};

export const OfferContext = createContext<OfferContextType>({} as OfferContextType);

OfferContext.displayName = 'Offer';

export const OfferProvider = ({ children }: { children: JSX.Element }): JSX.Element =>
{
    const [ offer, setOffer ]: [OfferType, any] = useState(null);
    const [ offerId, setOfferId ]: [string, any] = useState('');
    const [ validFrom, setValidFrom] : [string, any] = useState('');
    const { bffService } = useContext(BffServiceContext);
    const { tenantState: [ tenant ] }: any = useContext(TenantContext);
    const { pathname } = useLocation();

    const pathnameParts = pathname.split('/').filter(part => part);
    const isGiftOffer = Boolean(pathname.match(getGiftTranslationsRegex()));
    const isCampagin = Boolean(pathname.match(getCampaginTranslationsRegex()));
    const isCallbackUrl = Boolean(pathname.match(/callback/));
    const isUpgradeOffer = !isGiftOffer && ((!isCallbackUrl && pathnameParts.length === 2) || (isCallbackUrl && pathnameParts.length === 3));
    const subscriptionId = isUpgradeOffer ? pathnameParts[1] : null;
    const alternativeKavz = isCampagin ? pathnameParts[2] : null;

    useEffect(() =>
    {
        (async function getOffer()
        {
            if (bffService && offerId) {
                try {
                    const response = await bffService?.getOffer(offerId);
                    const offer = response?.data.data;

                    let active = [];

                    if (subscriptionId) {
                        const result = await bffService.getSubscriptions();

                        active = result.data.data.active;
                    }

                    const upgradeSubscription = subscriptionId && isUpgradeSubscription(subscriptionId, active);
                    const validFrom = subscriptionId && getUpgradeValidFrom(subscriptionId, active);

                    if (validFrom) {
                        setValidFrom(validFrom);
                    }

                    if (
                        (offer && !isGiftOffer && !isUpgradeOffer && offer.type !== 'normal') ||
                        (offer && isGiftOffer && !offer.sapData.offerKeyGift) ||
                        (offer && isUpgradeOffer && offer.type !== 'productchange') ||
                        (isUpgradeOffer && !upgradeSubscription)
                    ) {
                        window.location.href = tenant.links.pianoLandingPage;
                    } else if (offer) {
                        setOffer(offer);
                    }
                } catch {
                    window.location.href = tenant.links.pianoLandingPage;
                }
            }
        })();
    }, [offerId, bffService]);

    const offerValue = useMemo(() => (
        { offer, setOfferId, offerId, isGiftOffer, isUpgradeOffer, isCampagin, alternativeKavz, validFrom }
    ), [offer, offerId, alternativeKavz]);

    return (
        <OfferContext.Provider value={offerValue}>
            {children}
        </OfferContext.Provider>
    );
};
