import { useState, createContext, useMemo, useContext, useEffect } from 'react';
import { OfferContext } from './OfferContext';
import { OrderContext } from './OrderContext';
import { CustomerContext } from './CustomerContext';

interface PaymentContextType {
    payment: string;
    setPayment: (payment: string) => void;
}

const PaymentContext = createContext({} as PaymentContextType);

const PaymentProvider = ({ children }: { children: JSX.Element[] }): JSX.Element =>
{
    const [ payment, setPayment ] = useState<string>('');
    const { offer } = useContext(OfferContext);
    const { isOrderCreated, order } = useContext(OrderContext);
    const { customer } = useContext(CustomerContext);

    useEffect(() =>
    {
        if (offer && isOrderCreated && order.paymentMode) {
            const selectedPayment = customer.payment ? customer.payment.type.toLowerCase() : order.paymentMode;

            setPayment(selectedPayment);
        }
    }, [customer, isOrderCreated]);

    const paymentValue = useMemo(() => (
        { payment, setPayment }
    ), [payment]);

    return (
        <PaymentContext.Provider value={paymentValue}>
            {children}
        </PaymentContext.Provider>
    );
};

export {
    PaymentContext,
    PaymentProvider
};
