import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@tampmd/hyperion-ui/Typography';
import { Paper } from '@tampmd/hyperion-ui/Paper';
import { useContext } from 'react';
import { TenantContext } from '../../hooks/TenantContext';

export const NotFoundScene = (): JSX.Element =>
{
    const { t } = useTranslation();
    const { tenantState: [ tenant ], responsiveState: [ isMobile ] }: any = useContext(TenantContext);
    const domainParts = tenant.domainName.split('.');
    const secondLevelDomain = domainParts[domainParts.length - 2];
    const shopUrl = String(process.env.REACT_APP_SHOP_URL).replace('{tenant}', secondLevelDomain);

    return (
        <Paper id="not-found-page">
            <Paper classes={['not-found-content']}>
                <Typography variant="h1">404</Typography>
                <Trans i18nKey="notFoundPage.text">
                    <a href={ shopUrl } >&nbsp;</a>
                </Trans>  
            </Paper>
        </Paper>
    );
};
