import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@tampmd/hyperion-ui/Typography';
import { Alert } from '@tampmd/hyperion-ui/Alert';
import { TenantContext } from '../../../hooks/TenantContext';

export const AddressCompany = (): JSX.Element =>
{
    const { t } = useTranslation();
    const { tenantState: [ tenant ] }: any = useContext(TenantContext);

    return (
        <Alert status="info" icon classes={['mb-4']}>
            <Typography variant="text">
                {t('address.company.message.partOne')}
                <a href={tenant.contact.onlineForm}>
                    {t('footer.email.subTitle')}
                </a>
                {t('address.company.message.partTwo')}
                <a href={`tel:${tenant.contact.phone.replaceAll(' ', '')}`}>
                    {tenant.contact.phone}
                </a>
            </Typography>
        </Alert>
    );
};
