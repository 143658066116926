export const cmpIds = [
    {
        tenant: 'derbund',
        oneTrustCmpId: 'a1ce827f-742d-4d05-823f-6290aa2b5180'
    },
    {
        tenant: 'bazonline',
        oneTrustCmpId: 'b0a0e824-69ed-44d6-aeff-da43e65b630a'
    },
    {
        tenant: 'tdg',
        oneTrustCmpId: '3828236b-ab35-4cfa-bf6e-4275e65cb818'
    },
    {
        tenant: 'tagesanzeiger',
        oneTrustCmpId: 'a2fee2c7-21ea-4958-9282-21a0cc369b84'
    },
    {
        tenant: 'bernerzeitung',
        oneTrustCmpId: 'a2183d89-2185-40c1-959a-6885c9cc73e5'
    },
    {
        tenant: 'berneroberlaender',
        oneTrustCmpId: 'c6f7cdeb-3adf-4bea-b71a-e55297bafa4f'
    },
    {
        tenant: 'thunertagblatt',
        oneTrustCmpId: 'c3f0a7a1-e88d-4bc6-b961-a2e8300a58e8'
    },
    {
        tenant: 'langenthalertagblatt',
        oneTrustCmpId: 'b54d2f42-12f0-4632-9d83-d09356681c6c'
    },
    {
        tenant: 'zsz',
        oneTrustCmpId: '5403cc64-b2f8-4873-a789-9fc34e1d9779'
    },
    {
        tenant: 'zuonline',
        oneTrustCmpId: '37585dfa-f164-42ef-9c5c-da2128f3a0f5'
    },
    {
        tenant: 'landbote',
        oneTrustCmpId: 'a5c6b059-125e-4d69-93ee-24596c4a54f8'
    },
    {
        tenant: '24heures',
        oneTrustCmpId: '4b6699d8-0160-4f47-925e-766999f67056'
    },
    {
        tenant: 'bilan',
        oneTrustCmpId: '7c967a91-7f3b-45cf-b0df-c57edaa27944'
    },
    {
        tenant: 'fuw',
        oneTrustCmpId: '267798cf-831f-4735-9bb0-248870590b05'
    },
    {
        tenant: 'carteblanche',
        oneTrustCmpId: '85474ada-a317-43e1-b17c-e41d3ad34d63'
    },
    {
        tenant: 'carteb',
        oneTrustCmpId: '2b550235-b4b7-4830-bf3f-aee20490b512'
    },
    {
        tenant: 'schweizerfamilie',
        oneTrustCmpId: '96d19f37-4b49-4855-a973-c28b08cbfde9'
    },
    {
        tenant: 'sonntagszeitung',
        oneTrustCmpId: '01904fa1-b287-7b9d-8fd6-08f6d4bca629'
    },
    {
        tenant: 'lematindimanche',
        oneTrustCmpId: '01905e03-be99-74cb-9b8a-05cf2dad9fba'
    },
    {
        tenant: 'sihltaler',
        oneTrustCmpId: '0190dff9-23f4-7b34-be0f-2a545802c516'
    },
    {
        tenant: 'tamedia',
        oneTrustCmpId: '0190dffa-a126-7ca6-9735-99c06c8479b6'
    },
    {
        tenant: 'thalwileranzeiger',
        oneTrustCmpId: '0190dff9-a719-722b-b537-57410279443d'
    }
];
