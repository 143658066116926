import { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@tampmd/hyperion-ui/Typography';
import { Paper } from '@tampmd/hyperion-ui/Paper';
import { AddressForm } from '../Address/components/AddressForm';
import { StepHeader } from './components/StepHeader';
import { StepActions } from './components/StepActions';

export const DeliveryAddressStep: FC<{ isGift?: boolean }> = ({ isGift = false }) =>
{
    const [ isPrivate, setIsPrivate ] = useState(Boolean(true));
    const { t } = useTranslation();

    return (
        <Paper classes={['content__address-step']}>
            <StepHeader title={t('address.title')} steps={isPrivate}/>
            <Typography variant="h3" autoWidth inline onClick={() => setIsPrivate(true)} classes={['mb-3']}>
                {isGift ? t('address.giftDelivery') : t('address.delivery')}
            </Typography>
            <AddressForm formName="deliveryAddress"/>
            <StepActions/>
        </Paper>
    );
};
