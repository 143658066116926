import { useContext } from 'react';
import { Footer as ThemeFooter, FooterProps } from '@tampmd/hyperion-ui/Footer';
import { TenantContext } from '../../hooks/TenantContext';

export const Footer = (): JSX.Element =>
{
    const { tenantState: [ tenant ] }: any = useContext(TenantContext);
    const footerProps: FooterProps = {
        smallLogo: tenant.smallLogo,
        links: {
            social: {
                youtube: tenant.links.social.youtube,
                twitter: tenant.links.social.twitter,
                instagram: tenant.links.social.instagram,
                linkedIn: tenant.links.social.linkedIn,
                facebook: tenant.links.social.facebook
            },
            impressum: tenant.links.impressum,
            faq: tenant.links.faq,
            termsAndConditions: tenant.links.termsAndConditions,
            contact: tenant.contact.onlineForm,
            dataProtection: tenant.links.dataProtection
        },
        language: tenant.language,
        domainName: tenant.domainName,
        fullName: tenant.name
    };

    return <ThemeFooter {...footerProps}/>;
};
