import { useState, useEffect, useContext, createContext, useMemo } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';
import { TenantContext } from './TenantContext';
import { RedirectionService } from '../services/utils/Redirection/Redirection';

export const AuthenticationContext = createContext<AuthContextType>({} as AuthContextType);

AuthenticationContext.displayName = 'Authentication';

export interface AuthContextType {
    auth: string;
    setAuth: (token: string) => void;
    isNewUser: boolean;
    setIsNewUser: (newUser: boolean) => void;
}

export const AuthenticationProvider = ({ children }: { children: JSX.Element }): JSX.Element =>
{
    const [auth, setAuth] = useState('');
    const [isNewUser, setIsNewUser] = useState(false);
    const { tenantState: [ tenant ] }: any = useContext(TenantContext);
    const matchCallbackRoute = useRouteMatch('/callback');
    const { pathname } = useLocation();
    const cookies = new Cookies();
    const isLoggedInCookie = cookies.get('isLoggedIn');

    let isExpired = false;

    if (Boolean(isLoggedInCookie) && auth) {
        const decodedIdToken: any = jwtDecode(auth);

        isExpired = Date.now() >= decodedIdToken.exp * 1000;
    }

    useEffect(() =>
    {
        if (tenant.id && !matchCallbackRoute) {
            const redirection = new RedirectionService(tenant.id);

            if (!isLoggedInCookie || (Boolean(isLoggedInCookie) && isExpired)) {
                redirection.login(pathname);
            } else if (isLoggedInCookie) {
                (async function fetchToken()
                {
                    try {
                        const idToken = await redirection.getToken();

                        setAuth(idToken);
                    } catch (error) {
                        redirection.login(pathname);
                    }
                })();
            }
        }
    }, [tenant.id]);

    const authValue = useMemo(() => (
        { auth, setAuth, isNewUser, setIsNewUser }
    ), [auth]);

    return (
        <AuthenticationContext.Provider value={authValue}>
            {children}
        </AuthenticationContext.Provider>
    );
};

