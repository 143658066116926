import { useState, createContext, useMemo, Dispatch, SetStateAction } from 'react';
import { AddressStep } from '../components/checkoutSteps/AddressStep';
import { PaymentStep } from '../components/checkoutSteps/PaymentStep';
import { CheckoutStep } from '../components/checkoutSteps/CheckoutStep';

const CheckoutContext = createContext({});

CheckoutContext.displayName = 'Checkout';

const CheckoutProvider = ({ children }: { children: JSX.Element }): JSX.Element =>
{
    const [step, setStep]: [number, Dispatch<SetStateAction<number>>] = useState(1);
    const [stepContent, setStepContent]: [JSX.Element[], Dispatch<SetStateAction<JSX.Element[]>>] = useState(
        [<AddressStep key="1"/>, <PaymentStep key="3"/>, <CheckoutStep key="4"/>]
    );
    const isLastStep = step === stepContent.length;

    const checkoutValue = useMemo(() => (
        [step, setStep, stepContent, setStepContent, isLastStep]
    ), [step, stepContent]);

    return (
        <CheckoutContext.Provider value={checkoutValue}>
            {children}
        </CheckoutContext.Provider>
    );
};

export { CheckoutContext, CheckoutProvider };
