import { useContext, useEffect } from 'react';
import { TenantContext } from '../../hooks/TenantContext';

export const PianoLandingScene = (): JSX.Element =>
{
    const {
        tenantState: [ tenant ]
    }: any = useContext(TenantContext);

    useEffect(() =>
    {
        window.location.href = tenant.links.pianoLandingPage;
    }, []);

    return <></>;
};
