import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@tampmd/hyperion-ui/Typography';
import { Paper } from '@tampmd/hyperion-ui/Paper';
import { OfferContext } from '../../hooks/OfferContext';
import { CheckoutContext } from '../../hooks/CheckoutContext';

export const Sidebar = (): JSX.Element | null =>
{
    const { offer } = useContext(OfferContext);
    const [ , , , , isLastStep ]: any = useContext(CheckoutContext);
    const { t } = useTranslation();

    let jsx = null;

    if (offer) {
        jsx = (
            <Paper classes={['sidebar__order']}>
                <Typography variant="h1" inline autoWidth classes={['mb-3']}>{offer.data.name}</Typography>
                <Typography variant="h3" inline autoWidth classes={['mb-3', 'font-weight-bold']}>
                    {offer.data.description}
                </Typography>
                {
                    !isLastStep &&
                    <Typography variant="h3" inline autoWidth>{t('price')}: {offer.price} CHF</Typography>
                }
            </Paper>
        );
    }

    return jsx;
};
