import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { TRANSLATIONS_EN } from './en/translations';
import { TRANSLATIONS_FR } from './fr/translations';
import { TRANSLATIONS_DE } from './de/translations';

/**
 * @type {Object}
 */
const resources = {
    en: {
        translation: TRANSLATIONS_EN
    },
    fr: {
        translation: TRANSLATIONS_FR
    },
    de: {
        translation: TRANSLATIONS_DE
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en'
    });

export { i18n };
