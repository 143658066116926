import axios from 'axios';

const checkoutSourceClient = 'webcheckout';
const tokenResponseType = 'token';
const spliceStartPosition = -2;
const spliceDeleteCount = 1;
const grantType = 'refresh_token';

export class RedirectionService
{
    private tenantId: string;

    constructor(tenantId: string)
    {
        this.tenantId = tenantId;
    }

    public getUrl = (url: string): string =>
    {
        const initialUrlArray = url.split('.');

        initialUrlArray[1] = this.tenantId;

        return initialUrlArray.join('.');
    };

    /**
     * Redirects to authorize page.
     */
    public login = (pathname: string): void =>
    {
        const responseType = `response_type=${tokenResponseType}`;
        const sourceClient = `source_client=${checkoutSourceClient}`;
        const source = 'source=titancheckout';
        const pathName = `/checkout/callback${pathname}`;
        const redirectUri = `redirect_uri=${encodeURIComponent(window.location.origin + pathName)}`;

        window.location.href = `${this.getTransformedURL(
            this.tenantId,
            process.env.REACT_APP_IDENTITY_API_BASE_URL
        )}identity-service/auth/authorize?${responseType}&${sourceClient}&${redirectUri}&${source}`;
    };

    public getToken = async (): Promise<string> =>
    {
        const URL = `${this.getTransformedURL(this.tenantId, process.env.REACT_APP_IDENTITY_API_BASE_URL)}identity-service/auth/token`;
        const payloadBody = {
            grantType: grantType,
            sourceClient: checkoutSourceClient
        };
        const reqConfig = {
            withCredentials: true,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        };
        const { data: { id_token: idToken }} = await axios.post(URL, payloadBody, reqConfig);

        return idToken;
    };

    /**
     * Returns URL for specific tenant.
     */
    public getTransformedURL = (tenantId: string, environmentURL = ''): string =>
    {
        const urlArray = environmentURL.split('.');

        urlArray.splice(spliceStartPosition, spliceDeleteCount, tenantId);

        return urlArray.join('.');
    };
}
