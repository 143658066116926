import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Paper } from '@tampmd/hyperion-ui/Paper';
import { Typography } from '@tampmd/hyperion-ui/Typography';
import { Button } from '@tampmd/hyperion-ui/Button';
import { DefaultLayout } from '../../layouts/default/DefaultLayout';
import { Footer } from '../../components/Footer/Footer';
import { OrderContext } from '../../hooks/OrderContext';
import { OfferContext } from '../../hooks/OfferContext';
import { TenantContext } from '../../hooks/TenantContext';
import * as GoogleAnalytics from '../../services/GoogleAnalytics/GoogleAnalytics';

import ThankYouImage from './assets/ThankYouImage.svg';
import { CustomerContext } from '../../hooks/CustomerContext';

const mobileBreakpoint = 600;
const deVerticals = ['fuw', 'schweizerfamilie', 'sonntagszeitung'];
const frVerticals = ['lematindimanche', 'bilan'];

export const ThankYouScene = (): JSX.Element =>
{
    const [isSmallMobile, setIsSmallMobile] = useState(false);
    const { t } = useTranslation();
    const { order, isOrderCompleted, isPostprocessing, transactionCode } = useContext(OrderContext);
    const { offer }: any = useContext(OfferContext);
    const { tenantState: [ tenant ], responsiveState: [ isMobile ] }: any = useContext(TenantContext);
    const history = useHistory();
    const domainParts = tenant.domainName.split('.');
    const topLevelDomain = domainParts[domainParts.length - 1];
    const secondLevelDomain = domainParts[domainParts.length - 2];
    const domain = `${secondLevelDomain}.${topLevelDomain.replace('/', '')}`;
    const { customer } = useContext(CustomerContext);
    const myAccountUrl = String(process.env.REACT_APP_MY_ACCOUNT_URL).replace('{tenant}', secondLevelDomain);

    const checkWidth = () =>
    {
        const isSmallMobile = window.innerWidth <= mobileBreakpoint;

        setIsSmallMobile(isSmallMobile);
    };

    const getBannerBtnText = () => {
        let btnText = t('thankYou.bannerBtnText.version1');

        if (
            (tenant.language === 'de' && deVerticals.includes(tenant.id))
            || (tenant.language === 'fr' && frVerticals.includes(tenant.id))
        ) {
            btnText = t('thankYou.bannerBtnText.version2');
        }

        return btnText;
    };

    useEffect(() =>
    {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        !isOrderCompleted && history.push('/not-found');
        isOrderCompleted && GoogleAnalytics.pushTransactionDataLayer({
            order,
            offer,
            transactionCode,
            tenantId: tenant.id
        });
        checkWidth();
        window.addEventListener('resize', checkWidth);

        return () => window.removeEventListener('resize', checkWidth);
    }, []);

    return (
        <DefaultLayout colorPalette={tenant.color}>
            <DefaultLayout.Navbar logoUrl={isMobile ? tenant.smallLogo : tenant.logo}/>
            <DefaultLayout.Main>
                {
                    tenant.mobileApps && (
                        <Paper id="mobile-app-banner">
                            <Typography variant="text" autoWidth>
                                {t('thankYou.bannerText.part1')}
                                &nbsp;
                                <strong>{t('thankYou.bannerText.part2')}</strong>
                                &nbsp;
                                {t('thankYou.bannerText.part3')}
                            </Typography>
                            {
                                (tenant.mobileApps.qrCodeImage && !isMobile)
                                && <img src={tenant.mobileApps.qrCodeImage} alt="QR code"/>
                            }
                            {
                                (tenant.mobileApps.url && isMobile) && (
                                    <Button autoWidth onClick={() => {
                                        GoogleAnalytics.pushThankyouButtonDataLayer(getBannerBtnText(), customer.tamediaId);
                                        window.setTimeout(() => window.location.href = tenant.mobileApps.url, 0);
                                    }}>
                                        {getBannerBtnText()}
                                    </Button>
                                )
                            }
                        </Paper>
                    )
                }
                <Paper id="thank-you-page" classes={[tenant.mobileApps ? 'with-banner' : '']}>
                    <Paper classes={['thank-you-content']}>
                        <Typography variant="h1" autoWidth classes={['mb-3']}>
                            {t('thankYou.title')}
                        </Typography>
                        <Paper>
                            {
                                isSmallMobile && (
                                    <Paper classes={['thank-you-image']}>
                                        <img src={ThankYouImage} alt="thank you"/>
                                    </Paper>
                                )
                            }
                            {
                                isPostprocessing &&
                                (
                                    <Typography variant="intro" autoWidth classes={['mb-4']}>
                                        {t('thankYou.postprocessing')}
                                    </Typography>
                                )
                            }
                            <Typography variant="intro" autoWidth classes={['mb-4']}>
                                {t('thankYou.subtitle')}
                            </Typography>
                            <Typography variant="intro" autoWidth classes={['mb-5']}>
                                {t('thankYou.text')}
                            </Typography>
                        </Paper>
                        <Paper classes={['content-cta']}>
                            <Button autoWidth
                                onClick={() => {
                                    GoogleAnalytics.pushThankyouButtonDataLayer(t('thankYou.myAccountBtn'), customer.tamediaId);
                                    window.setTimeout(() => window.location.href = myAccountUrl, 0);
                                }}>
                                {t('thankYou.myAccountBtn')}
                            </Button>
                            <Button autoWidth variant="secondary" onClick={() => {
                                GoogleAnalytics.pushThankyouButtonDataLayer(t('thankYou.startPageBtn'), customer.tamediaId );
                                window.setTimeout(() => window.location.href = tenant.domainName, 0);
                            }}>
                                {t('thankYou.startPageBtn')} {domain}
                            </Button>
                        </Paper>
                    </Paper>
                    {
                        !isSmallMobile && (
                            <Paper classes={['thank-you-image']}>
                                <img src={ThankYouImage} alt="thank you"/>
                            </Paper>
                        )
                    }
                </Paper>
            </DefaultLayout.Main>
            <DefaultLayout.Footer>
                <Footer/>
            </DefaultLayout.Footer>
        </DefaultLayout>
    );
};
