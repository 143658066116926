import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@tampmd/hyperion-ui/Typography';
import { Paper } from '@tampmd/hyperion-ui/Paper';
import Skeleton from 'react-loading-skeleton';
import { FormContext } from '../../hooks/FormContext';
import { CheckoutContext } from '../../hooks/CheckoutContext';
import { OfferContext } from '../../hooks/OfferContext';

export const Address = (): JSX.Element =>
{
    const [ values ]: any = useContext(FormContext);
    const [ , setStep ]: any = useContext(CheckoutContext);
    const { isUpgradeOffer } = useContext(OfferContext);
    const { t } = useTranslation();
    const addressAssembly = values.address.assembly;
    const addressAssemblyLine4 = addressAssembly?.full.line4;
    const deliveryAddressAssembly = values.addDeliveryAddress ? values.deliveryAddress.assembly : null;
    const deliveryAddressAssemblyLine4 = deliveryAddressAssembly?.full?.line4;

    return (
        <>
            <Paper classes={['content__address']}>
                <Paper classes={['mb-3', 'd-flex', 'justify-content-between']}>
                    <Typography variant="text">{t('address.title')}</Typography>
                    {
                        !isUpgradeOffer
                        && (
                            <Typography variant="infotext" underline autoWidth inline onClick={() => setStep(1) }>
                                {t('button.change')}
                            </Typography>
                        )
                    }
                </Paper>
                <Paper classes={['mb-4']}>
                    {
                        addressAssembly ?
                            <>
                                <Typography variant="text">
                                    {`${addressAssembly.full.line0} ${addressAssembly.full.line1}`}
                                </Typography>
                                <Typography variant="text">{addressAssembly.full.line2}</Typography>
                                <Typography variant="text">{addressAssembly.full.line3}</Typography>
                                {
                                    addressAssemblyLine4 &&
                                    <Typography variant="text">{addressAssemblyLine4}</Typography>
                                }
                            </> :
                            <Skeleton count={3}/>
                    }
                </Paper>
            </Paper>
            {
                values.addDeliveryAddress
                && (
                    <Paper classes={['content__address']}>
                        <Paper classes={['mb-3', 'd-flex', 'justify-content-between']}>
                            <Typography variant="text">{t('address.delivery')}</Typography>
                            {
                                !isUpgradeOffer
                                && (
                                    <Typography variant="infotext" underline autoWidth inline onClick={() => setStep(2) }>
                                        {t('button.change')}
                                    </Typography>
                                )
                            }
                        </Paper>
                        <Paper classes={['mb-4']}>
                            {
                                deliveryAddressAssembly ?
                                    <>
                                        <Typography variant="text">
                                            {`${deliveryAddressAssembly.full.line0} ${deliveryAddressAssembly.full.line1}`}
                                        </Typography>
                                        <Typography variant="text">{deliveryAddressAssembly.full.line2}</Typography>
                                        <Typography variant="text">{deliveryAddressAssembly.full.line3}</Typography>
                                        {
                                            deliveryAddressAssemblyLine4 &&
                                            <Typography variant="text">{deliveryAddressAssemblyLine4}</Typography>
                                        }
                                    </> :
                                    <Skeleton count={3}/>
                            }
                        </Paper>
                    </Paper>
                )
            }
        </>
    );
};
