import { Links } from '@tampmd/utils/Tenant';

export interface TenantType {
    id: string;
    name: string;
    color: {
        primary: string;
        secondary: string;
    };
    language: string;
    domainName: string;
    contact: {
        onlineForm: string;
        phone: string;
    };
    favicon: string;
    logo: string;
    smallLogo: string;
    links: Links;
    mobileApps?: {
        url: string;
        qrCodeImage: string;
    };
}

export interface CustomerType {
    tamediaId: string;
    sapId: string;
    payment?: {
        ccAlias: string;
        maskedNumber: string;
        validToMonth: string;
        validToYear: string;
        country: string;
        type: string;
        datatransSSOId: string;
    };
}

export interface NotificationType {
    status?: 'success' | 'info' | 'danger' | 'warning' | undefined;
    description: any;
    linkText?: any;
    link?: any;
}

export enum addressTypeEnum {
    contractingAddress = '01',
    deliveryAddress = '10',
    billingAddress = '05',
    holidayAddress = '20',
}

export enum addressNameEnum {
    contractingAddress = 'contract',
    deliveryAddress = 'delivery',
    billingAddress = 'billing',
    holidayAddress = 'holiday',
}

export enum addressRoleEnum {
    mainAddress = 'GP',
    deliveryAddress = 'WE',
    invoiceRecipient = 'RE',
    payer = 'RG',
}

export enum salutationEnum {
    mr = '11',
    mrs = '12',
    company = '24',
    companyContactPersonMr = '21',
    companyContactPersonMrs = '22'
}

export interface AddressType {
    address: DeliveryAddress,
    deliveryAddress?: DeliveryAddress
}

export type OrderStatus = 'new' | 'settled' | 'postProcessing';

export interface OrderType {
    code: number;
    tamediaId: string;
    sapCustomerNumber?: string;
    orderDate?: string;
    status?: OrderStatus;
    paymentMode: string;
    gift?: boolean;
    offer?: {
        offerKey: string;
        campaignKey: string;
    };
    voucher?: {
        type: string;
        name: string;
        value: string;
    },
    price?: {
        offerPrice: string;
        currency: string;
        deliveryCost: string;
        discount: string;
        totalPrice: string;
        vat: string;
    },
    security?: {
        countryIP: string;
        countryAddress: string;
        countryCC: string;
    },
    datatrans?: {
        tamediaTransactionCode: string;
        transactionId: string;
    },
    printProduct?: string;
    paymentAddress?: AddressWithoutSAPNumber;
    deliveryAddress?: AddressWithoutSAPNumber;
}

export interface OrderWithPrecheckType extends OrderType {
    precheck?: {
        deliveryType: string;
        discount: number;
        edition: string;
        price: number;
        priceSingle: number;
        shippingCost: number;
        vat: number;
        validFrom: string;
    }
}

export interface DeliveryAddress {
    addressIdSf?: string;
    addressType: addressTypeEnum;
    addressRole: addressRoleEnum;
    validFrom: string;
    anred: salutationEnum;
    titel?: string;
    name1: string;
    name2: string;
    name3?: string;
    name4?: string;
    stras?: string;
    hausnhsnmr2?: string;
    street2?: string;
    ispadrbsnd?: string;
    land1: string;
    pstlz?: string;
    pstl2?: string;
    pfach?: string;
    pstl3?: string;
    ort01?: string;
    ortpf?: string;
    isptelp: string;
    ispteld?: string;
    isphandy?: string;
    ispemail?: string;
}

export interface AddressWithoutSAPNumber extends DeliveryAddress {
    addressId?: string;
    validTo?: string;
}

export interface AllAddressesType {
    pending: boolean;
    assembly: AddressAssembly;
    addresses: Address[];
}

export interface Address extends AddressWithoutSAPNumber {
    sapCustomerNumber?: string;
}

export interface AddressWithSapCustomerNumber {
    address: Address
}

export interface AddressWithOrderCode extends AddressWithSapCustomerNumber {
    orderCode?: string
}

export interface AddressAssembly {
    short: string;
    full: {
        anzzl: string;
        line0: string;
        line1: string;
        line2: string;
        line3: string;
        line4: string;
        line5: string;
    }
}

export interface PostAddressData {
    firstname?: string;
    lastname?: string;
    zip: string;
    city: string;
    country: string;
    street: string;
    housenumber: string;
    supplement?: string;
}
