import React, { Component } from 'react';
import { Theme } from '@tampmd/hyperion-ui/Theme';
import { Navbar as ThemeNavbar } from '@tampmd/hyperion-ui/Navbar';
import { Brand } from '@tampmd/hyperion-ui/Brand';
import { Grid, GridItem } from '@tampmd/hyperion-ui/Grid';
import { Paper } from '@tampmd/hyperion-ui/Paper';
import { TenantContext } from '../../hooks/TenantContext';
import { DomainService } from '@tampmd/utils/Domain';
import { getOne } from '../../services/Tenant/Tenant';
import { i18n } from '../../locales/i18next';

interface DefaultLayoutProps {
    colorPalette: {
        primary: string;
        secondary: string;
    }
    children?: React.ReactNode;
}

export class DefaultLayout extends Component<DefaultLayoutProps>
{
    static contextType = TenantContext;

    async componentDidMount(): Promise<void>
    {
        const domainService = new DomainService();

        const {
            tenantState: [ , setTenant ],
            dataState: [ , setIsLoaded ]
        }: any = this.context;

        const tenant = await getOne(domainService.getDomain());

        setTenant(tenant);
        await i18n.changeLanguage(tenant.language);
        setIsLoaded(true);
    }

    static Navbar = ({ logoUrl }: { logoUrl: string }): JSX.Element => (
        <ThemeNavbar custom>
            <Paper classes={['col-12 col-lg-10 offset-lg-1 py-0 pl-1 pr-3 px-lg-0 d-flex']}>
                <Brand url={logoUrl} />
            </Paper>
        </ThemeNavbar>
    );

    static Main = ({ children }: { children: React.ReactNode }): JSX.Element => (
        <Grid classes={['titan-checkout__main']}>
            {children}
        </Grid>
    );

    static Sidebar = ({ children }: { children: React.ReactNode }): JSX.Element => (
        <GridItem classes={['titan-checkout__sidebar']}>
            {children}
        </GridItem>
    );

    static Content = ({ children }: { children: React.ReactNode }): JSX.Element => (
        <GridItem classes={['titan-checkout__content']}>
            {children}
        </GridItem>
    );

    static Footer = ({ children }: { children: React.ReactNode }): JSX.Element => (
        <Paper classes={['titan-checkout__footer', 'col-12', 'p-0']}>
            {children}
        </Paper>
    );

    render(): JSX.Element
    {
        const {
            dataState: [ isLoaded ]
        }: any = this.context;

        return (
            <React.Fragment>
                {
                    isLoaded &&
                    <Theme variant="fluid" colorPalette={{
                        primary: this.props.colorPalette.primary,
                        secondary: this.props.colorPalette.secondary,
                        primaryDark: '',
                        secondaryDark: '',
                        secondaryBtn: '',
                        secondaryBtnHover: '',
                        secondaryBtnDark: '',
                        secondaryBtnHoverDark: ''
                    }} classes={['titan-checkout p-0']}>
                        {this.props.children}
                    </Theme>
                }
            </React.Fragment>
        );
    }
}
