import { Paper } from '@tampmd/hyperion-ui/Paper';
import { Select } from '@tampmd/hyperion-ui/Select';
import { Dispatch, SetStateAction, useState } from 'react';
import { Dialog } from '@tampmd/hyperion-ui/Dialog';
import { PostAddressData } from '../../../types/types';
import { Typography } from '@tampmd/hyperion-ui/Typography';
import { Button } from '@tampmd/hyperion-ui/Button';
import { useTranslation } from 'react-i18next';

interface Option {
    value: string,
    name: string
}

interface FieldState {
  value: string,
  options: Option[]
  }

export interface FieldProps {
    addresses: PostAddressData[]
    toggleAddress: boolean;
    setToggleAddress: Dispatch<SetStateAction<boolean>>
    switchStep: any
    updateValuesWithPostApiAddress: any,
  }

/**
 * @class
 */
export const SelectAddressComponent = (props: FieldProps): JSX.Element =>

{
    const { t } = useTranslation();

    // eslint-disable-next-line
    const options = props.addresses.map((address: PostAddressData, index: number) => {
        return { value: index.toString(), name: `${address.street}` + ' ' + address.housenumber + ', ' + address.city + ' ' + address.zip };
    });

    const initialState: FieldState = {
        value: '0',
        options: options
    };

    const [state, setState] = useState(initialState);


    const closeDialog = async () => {
        if (props.toggleAddress) {
            props.setToggleAddress(false);
        } 
    };

    const handleChange = async (value: string) => {
        setState(prevState => ({
            ...prevState,
            value
        }));
    };

    const handleOnClick = async () => {
        await props.updateValuesWithPostApiAddress(props.addresses.find((address, index) => index === (parseInt(state.value))));
    };


    return (
        <Dialog onClose={closeDialog}>
            <Paper classes={['row', 'p-3', 'd-flex']}>
                <Paper classes={['col-md-10', 'mb-md-0']}>
                    <Typography variant="text" classes={['font-weight-bold', 'pl-2']}>{t('address.form.addressConfirmation')}</Typography>
                    <Typography variant="text" classes={['pl-2']}>{t('address.form.addressDropdown')}</Typography>
                </Paper>
                <Paper classes={['col-md-8', 'mb-3', 'mt-4', 'mb-md-0']}>
                    <Select value={state.value} options={options} onChange={val => handleChange(val)}/>
                </Paper>
                <Button
                    custom
                    autoWidth
                    onClick={() => handleOnClick()}
                    classes={['px-3', 'mt-4']}
                >
                    <Paper classes={['d-flex', 'align-items-center', 'justify-content-center']}>
                        <Typography variant="text" color="white">
                            {t('address.form.continueButton')}
                        </Typography>
                    </Paper>
                </Button>
            </Paper>
        </Dialog>
    );
};
