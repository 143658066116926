import Cookies from 'universal-cookie';
import momentJs from 'moment';
import { Offer } from '../apis/Bff/types';
import { OrderWithPrecheckType } from '../../types/types';

interface DataCheckoutLayerProps {
    step: number;
    tenantId: string;
    offer: Offer;
    option?: string;
    orderId?: number;
    email?: string;
}

interface DataTransactionLayerProps {
    order: OrderWithPrecheckType;
    offer: Offer;
    tenantId: string;
    transactionCode: string;
}

const cookies = new Cookies();

const getFirstPartOfCat = (subscriptionTerm: string): string =>
{
    let categoryFirstPart;

    switch (subscriptionTerm) {
    case 'monthly_1':
        categoryFirstPart = 'month/30';
        break;
    case 'quarterly_3':
        categoryFirstPart = 'quarter/90';
        break;
    case 'halfyearly_6':
        categoryFirstPart = 'half-year/180';
        break;
    case 'year':
    case 'yearly_12':
    case 'monthly_24':
    case 'monthly_36':
        categoryFirstPart = 'year/360';
        break;
    default:
        categoryFirstPart = 'none';
    }

    return categoryFirstPart;
};

const getSecondPartOfCat = (offerType: string): string => (
    offerType === 'productChange' ? 'upgrade': 'default'
);

const getThirdPartOfCat = (studentOffer: boolean) => (studentOffer ? 'studentoffer': 'default');

const getCategory = (subscriptionTerm: string, offerType: string, studentOffer: boolean): string => (
    `${getFirstPartOfCat(subscriptionTerm)}/${getSecondPartOfCat(offerType)}/${getThirdPartOfCat(studentOffer)}`
);

const getName = (bundleName: string): string => bundleName.split('|')[1].trim().toLowerCase();

const getVariant = (bundleId: string): string =>
{
    const bundleIdLastPart = bundleId.split('-')[2] || bundleId;

    let variant;

    switch (bundleIdLastPart) {
    case 'DILI':
        variant = 'digitallight';
        break;
    case 'DIGI':
    case 'SOIR':
        variant = 'digital';
        break;
    case 'WEEK':
    case 'WEEKD':
        variant = 'weekend';
        break;
    case 'CLAS':
        variant = 'classic';
        break;
    case 'PRON':
        variant = 'printonly';
        break;
    case 'CLPD':
        variant = 'classicplus';
        break;
    case 'ANALY':
        variant = 'analyse';
        break;
    default:
        variant = 'none';
    }

    return variant;
};

/**
 * Products array always has one element (multiple purchase is not possible).
 *
 * step - 1: initial load
 *        2: address form
 *        3: selected payment (last step)
 * option - referrer: initial load
 *          undefined: address form
 *          paymentType: last step
 * id - id of the offer
 * name - second part of bundle name, split by pipe (|), trimed and lowercased
 * price - price of the offer
 * category - part one: take subscriptionTerm of the offer and run it through 'getFirstPartOfCategory' mapping
 *            part two: default, except offer's type is productChange, then upgrade
 *            part three: default, except offer's studentOffer is true, then studentoffer
 * variant - bundle id, split by dash (-), take last part and run it through 'getVariant' mapping
 * brand - tenantId
 * quantity - always 1
 */
export const pushCheckoutDataLayer = ({ step, option, orderId, offer, tenantId, email }: DataCheckoutLayerProps): void =>
{
    let dataLayerOption;
    let shouldPushDataLayer = true;

    if (option) {
        dataLayerOption = option;
    } else if (step === 1) {
        dataLayerOption = document.referrer;
    }

    if (orderId) {
        if (localStorage.getItem('orders')) {
            const orders = JSON.parse(localStorage.getItem('orders') as string);
            const activeOrders = orders
                .map((order: any) => (
                    Math.sign(momentJs().diff(order.expireAt)) === 1 ? null : order
                ))
                .filter((order: any) => order);
            const existingOrder = activeOrders.filter((order: any) => order.orderId === orderId).shift();

            if (existingOrder) {
                shouldPushDataLayer = false;
            } else {
                activeOrders.push({
                    orderId,
                    expireAt: momentJs().add(1, 'day')
                });
                localStorage.setItem('orders', JSON.stringify(activeOrders));
            }
        } else {
            localStorage.setItem('orders', JSON.stringify([
                {
                    orderId,
                    expireAt: momentJs().add(1, 'day')
                }
            ]));
        }
    }

    (window as any).dataLayer = (window as any).dataLayer || [];
    shouldPushDataLayer && (window as any).dataLayer.push({
        'event': 'ecommerce_checkout',
        'ecommerce': {
            'checkout': {
                'actionField': {
                    'step': step,
                    'option': dataLayerOption
                },
                'products': [{
                    'id': offer.code,
                    'name': getName(offer.bundle.data.name),
                    'price': Number(offer.price).toFixed(2),
                    'category': getCategory(offer.subscriptionTerm, offer.type, offer.studentOffer),
                    'variant': getVariant(offer.bundle.bundleId),
                    'brand': tenantId,
                    'quantity': 1
                }]
            },
            'user_email': email
        }
    });
};

export const pushTransactionDataLayer = ({ order, offer, tenantId, transactionCode }: DataTransactionLayerProps): void => {
    const payment = new RegExp('visa|master', 'gi').test(order.paymentMode)
        ? `cc-${order.paymentMode}`
        : order.paymentMode;

    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
        'event': 'ecommerce_transaction',
        'payment_method': payment,
        'ecommerce': {
            'currencyCode': 'CHF',
            'purchase': {
                'actionField': {
                    'id': transactionCode || order.code,
                    'affiliation': tenantId,
                    'revenue': Number(order.precheck?.price).toFixed(2),
                    'tax': Number(order.precheck?.vat).toFixed(2),
                    'coupon': ''
                },
                'products': [{
                    'name': getName(offer.bundle.data.name),
                    'id': offer.code,
                    'price': Number(offer.price).toFixed(2),
                    'brand': tenantId,
                    'category': getCategory(offer.subscriptionTerm, offer.type, offer.studentOffer),
                    'variant': getVariant(offer.bundle.bundleId),
                    'quantity': 1,
                    'coupon': ''
                }]
            },
            'user_email': order.paymentAddress?.ispemail
        }
    });
};

export const pushRegistrationDataLayer = (): void => {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
        'event': 'login',
        'event_action': 'registration'
    });
    if (cookies.get('gatewayNewUser') === 'yes') { cookies.remove('gatewayNewUser', { path: '/' }); }
};

export const pushThankyouButtonDataLayer = (label: string, tamediaId: string): void =>
{
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
        'event': 'ecommerce_thankyou_button_click',
        'event_category': 'E-Commerce',
        'event_action': 'button_click',
        'event_label': label,
        'tamedia_id': tamediaId
    });
};
