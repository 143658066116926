import { Head } from './components/Head/Head';
import { ContextProviders } from './components/ContextProviders/ContextProviders';
import { Routes } from './routes/Routes';

import './sass/main.scss';

export const Checkout = (): JSX.Element => (
    <ContextProviders>
        <Head/>
        <Routes/>
    </ContextProviders>
);
