import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Form } from '@tampmd/hyperion-ui/Form';
import { Typography } from '@tampmd/hyperion-ui/Typography';
import { Grid, GridItem } from '@tampmd/hyperion-ui/Grid';
import { Paper } from '@tampmd/hyperion-ui/Paper';
import { Input } from '@tampmd/hyperion-ui/Input';
import { RadioGroup } from '@tampmd/hyperion-ui/RadioGroup';
import { Checkbox } from '@tampmd/hyperion-ui/Checkbox';
import { Select } from '@tampmd/hyperion-ui/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FormContext } from '../../../hooks/FormContext';
import { PopupContext } from '../../../hooks/PopupContext';
import { OrderContext } from '../../../hooks/OrderContext';
import { validateField } from '../../../services/utils/Validation/Validation';
import { OfferContext } from '../../../hooks/OfferContext';

const SkeletonComponent = (): JSX.Element =>
{
    const OneColumnSkeleton = () => (
        <Grid>
            <GridItem classes={['col-12 col-md-6']}><Skeleton count={1} height={'40px'} /></GridItem>
        </Grid>
    );

    const TwoColumnsSkeleton = () => (
        <Grid classes={['my-2']}>
            <GridItem classes={['col-12 col-md-6 mb-2 mb-md-0']}><Skeleton count={1} height={'40px'} /></GridItem>
            <GridItem classes={['col-12 col-md-6']}><Skeleton count={1} height={'40px'} /></GridItem>
        </Grid>
    );

    return (
        <Paper classes={['d-flex', 'flex-column']}>
            <OneColumnSkeleton />
            <TwoColumnsSkeleton />
            <TwoColumnsSkeleton />
            <TwoColumnsSkeleton />
            <Grid classes={['mb-2']}>
                <GridItem classes={['col-12 col-md-6 offset-md-6']}><Skeleton count={1} height={'40px'} /></GridItem>
            </Grid>
            <TwoColumnsSkeleton />
            <TwoColumnsSkeleton />
            <OneColumnSkeleton />
        </Paper>
    );
};

const AddressFormComponent = ({ formName }: { formName: 'address' | 'deliveryAddress' }): JSX.Element =>
{
    const [ values, updateValue, countries ]: any = useContext(FormContext);
    const [ , setPopup ]: any = useContext(PopupContext);
    const { order: { deliveryAddress }} = useContext(OrderContext);
    const { isGiftOffer } = useContext(OfferContext);
    const [ zipCodeErrorMessage, setZipCodeErrorMessage ]: [string, any] = useState('');
    const [ addressCompletionErrorMessage, setAddressCompletionErrorMessage ]: [string, any] = useState('');
    const { t } = useTranslation();
    const isAddressForm = formName === 'address';
    const isDeliveryAddressForm = formName === 'deliveryAddress';
    const isNameFieldDisabled = Boolean(deliveryAddress) && (isAddressForm || (isDeliveryAddressForm && !isGiftOffer));
    const {
        salutation,
        name,
        surname,
        street,
        streetNumber,
        addressCompletion,
        postOffBox,
        postOffBoxNoNr,
        zipCode,
        city,
        country,
        phone
    } = values[formName];

    useEffect(() =>
    {
        if (country.value === 'CH') {
            const { error } = validateField(
                'zipCode',
                zipCode.value.trimStart(),
                `${t('address.form.validation.zipCode')}`);

            setZipCodeErrorMessage(error?.details[0].message);
        }

        if (deliveryAddress && formName === 'deliveryAddress' && name.value === '' && !isGiftOffer) {
            const {
                salutation: addressSalutation,
                name: addressName,
                surname: addressSurname,
                street: addressStreet,
                streetNumber: addressStreetNumber,
                addressCompletion: addressAddressCompletion,
                postOffBox: addressPostOffBox,
                postOffBoxNoNr: addressPostOffBoxNoNr,
                zipCode: addressZipCode,
                city: addressCity,
                country: addressCountry,
                phone: addressPhone
            } = values['address'];

            updateValue({ form: null, name: formName, value: {
                salutation: addressSalutation,
                name: addressName,
                surname: addressSurname,
                street: addressStreet,
                streetNumber: addressStreetNumber,
                addressCompletion: addressAddressCompletion,
                postOffBox: addressPostOffBox,
                postOffBoxNoNr: addressPostOffBoxNoNr,
                zipCode: addressZipCode,
                city: addressCity,
                country: addressCountry,
                phone: addressPhone
            }});
        }
    }, []);

    const getPlaceholdText = (form: string, field: string): string => (
        `${t(`address.form.field.${field}`)} ${values[form][field].isRequired ? '*' : ''}`
    );

    const selectCountriesData = [
        ...(
            values[formName].country.value === '' ?
                [{ name: `${getPlaceholdText(formName, 'country')}`, value: '' }] :
                []
        ),
        ...countries
    ];

    const salutationOptions = [
        { name: 'mr', label: `${t('address.form.field.mr')}`, disabled: isNameFieldDisabled },
        { name: 'mrs', label: `${t('address.form.field.mrs')}`, disabled: isNameFieldDisabled }
    ];

    const handleCountrySelection = (selectedCountry: string): void =>
    {
        const formValues = values[formName];

        const { country: { value: currentSelectedCountry }, zipCode: { value: currentZipCodeValue }} = formValues;

        updateValue({ form: null, name: formName, value: {
            ...formValues,
            country: { ...formValues.country, value: selectedCountry },
            ...(currentSelectedCountry && { zipCode: { ...formValues.zipCode, value: '' }})
        }});

        if (selectedCountry === 'CH' && !currentSelectedCountry) {
            const { error } = validateField(
                'zipCode',
                currentZipCodeValue.trimStart(),
                `${t('address.form.validation.zipCode')}`);

            setZipCodeErrorMessage(error?.details[0].message);
        } else {
            setZipCodeErrorMessage('');
        }
    };

    const handleZipCodeChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void =>
    {
        const { name: zipCodeFieldName, value } = target;

        if (country.value === 'CH') {
            const { error } = validateField(
                zipCodeFieldName,
                value.trimStart(),
                `${t(`address.form.validation.${zipCodeFieldName}`)}`);

            setZipCodeErrorMessage(error?.details[0].message);
        }

        updateValue({ form: formName, name: zipCodeFieldName, value });
    };

    const handleAddressCompletionChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void =>
    {
        const { name: addressCompletionFieldName, value } = target;
        const { error } = validateField(
            addressCompletionFieldName,
            value.trimStart(),
            `${t(`address.form.validation.${addressCompletionFieldName}`)}`);

        setAddressCompletionErrorMessage(error?.details[0].message);
        updateValue({ form: formName, name: addressCompletionFieldName, value });
    };

    return (
        <Form autocomplete="off">
            <Paper classes={['d-flex', 'align-items-center']}>
                <Typography variant="text" autoWidth inline classes={['mr-4']}>
                    {getPlaceholdText(formName, 'salutation')}
                </Typography>
                <RadioGroup items={salutationOptions} value={salutation.value} borders={false}
                    onChange={
                        ({ target }) => updateValue({ form: formName, name: 'salutation', value: target.name })
                    }
                />
            </Paper>
            <Paper classes={['row-wrapper', 'd-flex']}>
                <Input value={name.value} name="name" label={getPlaceholdText(formName, 'name')}
                    disabled={isNameFieldDisabled}
                    onChange={
                        ({ target }) => updateValue({ form: formName, name: target.name, value: target.value })
                    }
                />
                <Input value={surname.value} name="surname" label={getPlaceholdText(formName, 'surname')}
                    disabled={isNameFieldDisabled}
                    onChange={
                        ({ target }) => updateValue({ form: formName, name: target.name, value: target.value })
                    }
                />
            </Paper>
            <Paper classes={['row-wrapper', 'd-flex']}>
                <Input value={street.value} name="street" label={getPlaceholdText(formName, 'street')}
                    disabled={postOffBox.value !== '' || postOffBoxNoNr.value}
                    onChange={
                        ({ target }) => updateValue({ form: formName, name: target.name, value: target.value })
                    }
                />
                <Input value={streetNumber.value} name="streetNumber"
                    label={getPlaceholdText(formName, 'streetNumber')}
                    disabled={postOffBox.value !== '' || postOffBoxNoNr.value}
                    onChange={
                        ({ target }) => updateValue({ form: formName, name: target.name, value: target.value })
                    }
                />
            </Paper>
            <Paper classes={['row-wrapper', 'd-flex', 'flex-column']}>
                <Paper classes={['d-md-flex  d-block', 'align-items-start']}>
                    <Input value={addressCompletion.value} name="addressCompletion"
                        label={getPlaceholdText(formName, 'addressCompletion')}
                        onChange={handleAddressCompletionChange}
                        error={Boolean(addressCompletionErrorMessage)}
                        helperText={addressCompletionErrorMessage}
                    />
                    <Paper classes={[ 'd-flex ml-md-3 pmd-input-wrapper flex-row align-items-center']}>
                        <Input value={postOffBox.value} name="postOffBox" classes={['mb-0 flex-grow-1 mw-100']}
                            label={getPlaceholdText(formName, 'postOffBox')}
                            disabled={postOffBoxNoNr.value || street.value || streetNumber.value}
                            onChange={
                                ({ target }) => updateValue({ form: formName, name: target.name, value: target.value })
                            }
                        />
                        <FontAwesomeIcon icon={faInfoCircle} className="ml-2 mr-2"
                            onClick={() => setPopup({
                                isOpen: true,
                                content: <div>
                                    <Typography variant="h3" classes={['my-2']}>{t('address.form.infoMessage')}
                                    </Typography>
                                    <p>{t('address.form.infoMessageText')}</p>
                                </div>
                            })}
                        />
                    </Paper>
                </Paper>
                <Paper classes={['flex-row row-wrapper']}>
                    <Checkbox label={getPlaceholdText(formName, 'postOffBoxNoNr')}
                        name="postOffBoxNoNr"
                        classes={['pmd-input-wrapper ml-auto mb-3']}
                        checked={postOffBoxNoNr.value}
                        disabled={postOffBox.value || street.value || streetNumber.value}
                        onChange={
                            ({ target }) => updateValue({
                                form: formName, name: target.name, value: target.checked
                            })
                        }
                    />
                </Paper>
            </Paper>
            <Paper classes={['row-wrapper', 'd-flex']}>
                <Input classes={['mt-0']} value={zipCode.value} name="zipCode"
                    label={getPlaceholdText(formName, 'zipCode')}
                    onChange={handleZipCodeChange}
                    error={Boolean(zipCodeErrorMessage)}
                    helperText={zipCodeErrorMessage}
                />
                <Input classes={['mt-0']} value={city.value} name="city"
                    label={getPlaceholdText(formName, 'city')}
                    onChange={
                        ({ target }) => updateValue({ form: formName, name: target.name, value: target.value })
                    }
                />
            </Paper>
            <Paper classes={['row-wrapper', 'd-flex']}>
                <Select value={country.value} options={selectCountriesData}
                    onChange={handleCountrySelection}
                />
                <Input value={phone.value} name="phone" label={getPlaceholdText(formName, 'phone')}
                    onChange={
                        ({ target }) => updateValue({ form: formName, name: target.name, value: target.value })
                    }
                />
            </Paper>
            {
                formName === 'address' &&
                <Checkbox label={t('address.form.field.addDeliveryAddress')} name="addDeliveryAddress"
                    checked={values.addDeliveryAddress}
                    disabled={isGiftOffer}
                    onChange={
                        ({ target }) => updateValue({ form: null, name: target.name, value: target.checked })
                    }
                />
            }
        </Form>
    );
};

export const AddressForm = ({ formName }: { formName: 'address' | 'deliveryAddress' }): JSX.Element =>
{
    const [ values ]: any = useContext(FormContext);

    return values.isLoading ? <SkeletonComponent/> : <AddressFormComponent formName={formName} />;
};

