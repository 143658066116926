import React, { useContext, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@tampmd/hyperion-ui/Dialog';
import { Notification } from '@tampmd/hyperion-ui/Notification';
import { DefaultLayout } from '../../layouts/default/DefaultLayout';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Footer } from '../../components/Footer/Footer';
import { DeliveryAddressStep } from '../../components/checkoutSteps/DeliveryAddressStep';
import { TenantContext } from '../../hooks/TenantContext';
import { PopupContext } from '../../hooks/PopupContext';
import { CheckoutContext } from '../../hooks/CheckoutContext';
import { OfferContext } from '../../hooks/OfferContext';
import { BffServiceContext } from '../../hooks/BffServiceContext';
import { CustomerContext } from '../../hooks/CustomerContext';
import { NotificationContext } from '../../hooks/NotificationContext';
import { OrderContext } from '../../hooks/OrderContext';
import { PaymentContext } from '../../hooks/PaymentContext';
import { AuthenticationContext } from '../../hooks/AuthenticationContext';
import * as GoogleAnalytics from '../../services/GoogleAnalytics/GoogleAnalytics';

const tabletBreakpoint = 992;

export const CheckoutScene = (): JSX.Element =>
{
    const {
        tenantState: [ tenant ],
        responsiveState: [ isMobile, setIsMobile ]
    }: any = useContext(TenantContext);
    const { bffService } = useContext(BffServiceContext);
    const [ popup, setPopup ]: any = useContext(PopupContext);
    const [ step, setStep, stepContent, setStepContent ]: any = useContext(CheckoutContext);
    const { offer, setOfferId, isGiftOffer, isCampagin }: any = useContext(OfferContext);
    const { setCustomer } = useContext(CustomerContext);
    const { notification, setNotification } = useContext(NotificationContext);
    const { isOrderCreated, isOrderCompleted, order, setIsRedirected } = useContext(OrderContext);
    const { setPayment } = useContext(PaymentContext);
    const { isNewUser } = useContext(AuthenticationContext);
    const { offerId, kavz }: { offerId: string, kavz: string } = useParams();
    const query = useLocation().search;
    const history = useHistory();
    const { t } = useTranslation();
    const email = order.paymentAddress?.ispemail;

    useEffect(() => {
        if (isNewUser) {
            GoogleAnalytics.pushRegistrationDataLayer();
        }
    }, [isNewUser]);

    useEffect(() =>
    {
        if (isOrderCreated) {
            GoogleAnalytics.pushCheckoutDataLayer({ step: 1, offer, tenantId: tenant.id, orderId: order.code, email });
            GoogleAnalytics.pushCheckoutDataLayer({ step: 2, offer, tenantId: tenant.id, email });
        }
    }, [isOrderCreated]);

    useEffect(() =>
    {
        if (isOrderCompleted) {
            history.push('/thank-you');
        } else {
            setOfferId(offerId);
            checkWidth();
            window.addEventListener('resize', checkWidth);
        }

        return () =>
        {
            window.removeEventListener('resize', checkWidth);
        };
    }, []);

    useEffect(() =>
    {
        const queryParams = queryString.parse(query);
        const { status, datatransTrxId } = queryParams;

        if (datatransTrxId && bffService && setCustomer) {
            if (status === 'success') {
                (async function checkTransactionStatus()
                {
                    try {
                        const { data:
                            { data: userData }
                        } = await bffService.checkTransactionStatus(datatransTrxId as string);
                        const paymentType = userData.payment && userData.payment.type.toLowerCase();

                        setCustomer(userData);
                        setPayment(paymentType as string);
                        setNotification({
                            status: 'success',
                            description: `${t('payment.changeSuccess')}`
                        });
                    } catch (error: any) {
                        setNotification({
                            status: 'danger',
                            description: error.response.data.data.message
                        });
                    }
                })();
            } else if (status === 'error') {
                setNotification({
                    status: 'danger',
                    description: `${t('payment.changeFailed')}`
                });
            }

            const newStepContent = [...stepContent];

            if (isGiftOffer) {
                history.push(`/${t('gift')}/${offerId}`);
                stepContent.length < 4 && newStepContent.splice(1, 0, <DeliveryAddressStep key="2" isGift={isGiftOffer}/>);
                setStepContent(newStepContent);
            } else if (isCampagin) {
                history.push(`/${t('campagin')}/${offerId}/${kavz}`);
            } else {
                history.push(`/${offerId}`);
            }
            setStep(newStepContent.length - 1);
            setIsRedirected(true);
        }
    }, [query, bffService, setCustomer]);

    const checkWidth = () =>
    {
        const isMobile = window.innerWidth <= tabletBreakpoint;

        setIsMobile(isMobile);
    };

    return (
        <React.Fragment>
            {
                (bffService && isOrderCreated) &&
                <DefaultLayout colorPalette={tenant.color}>
                    <DefaultLayout.Navbar logoUrl={isMobile ? tenant.smallLogo : tenant.logo}/>
                    <DefaultLayout.Main>
                        <DefaultLayout.Sidebar>
                            <Sidebar/>
                        </DefaultLayout.Sidebar>
                        <DefaultLayout.Content>
                            {stepContent[step - 1]}
                        </DefaultLayout.Content>
                    </DefaultLayout.Main>
                    <DefaultLayout.Footer>
                        <Footer/>
                    </DefaultLayout.Footer>
                    {
                        popup.isOpen &&
                        <Dialog onClose={() => setPopup({ isOpen: false, content: <div/> })}>
                            {popup.content}
                        </Dialog>
                    }
                    {
                        notification.description &&
                        <Notification status={notification.status}
                            onClose={() => setNotification({ description: '' })}>
                            {notification.description}
                            {notification.linkText &&
                            <a href={notification.link} target="_blank" rel="noreferrer"
                                className="ml-1 link-general">
                                {notification.linkText}
                            </a>}
                        </Notification>
                    }
                </DefaultLayout>
            }
        </React.Fragment>
    );
};
