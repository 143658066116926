export const TRANSLATIONS_EN = {
    'address': {
        'title': 'Address',
        'private': 'Home address',
        'delivery': 'Delivery Address',
        'giftDelivery': 'Delivery Address',
        'company': {
            'title': 'Company address',
            'message': {
                'partOne': 'To set up a company account, please contact our customer service via ',
                'partTwo': ' or at '
            }
        },
        'form': {
            'title': 'Change address',
            'field': {
                'salutation': 'Salutation',
                'mr': 'Mr',
                'mrs': 'Mrs',
                'name': 'First name',
                'surname': 'Last name',
                'street': 'Street',
                'streetNumber': 'Number',
                'addressCompletion': 'Address Completion',
                'postOffBox': 'Post office box',
                'postOffBoxNoNr': 'P.O. Box without a number',
                'zipCode': 'Zip code',
                'city': 'Place',
                'country': 'Choose country',
                'phone': 'Phone number (+41)',
                'addDeliveryAddress': 'Delivery will be made to a different address.'
            },
            'validation': {
                'zipCode': 'Please check the postal code',
                'addressCompletion': 'The address completion must not be longer than 35 characters. Please use abbreviations where possible.'
            },
            'infoMessage': 'Post office box',
            'infoMessageText': 'If you have entered a street, you can not enter a P.O. box. When removing the street value, you will be able to enter a P.O. box.',
            'notificationPostProcessing': 'Address is in post-processing state.',
            'error': {
                'duplicateData': 'Duplicate data',
                'dataNotValid': {
                    'partOne': 'Your address is being processed. An order can only be entered once the address has been adjusted in our system. Please contact our',
                    'customerService': 'customer service.'
                }
            },
            'addressConfirmation': "Are you sure you provided the correct address details?",
            'addressDropdown': "Please check validated suggestions from dropdown list",
            'continueButton': 'continue'
        }
    },
    'payment': {
        'title': 'Payment method',
        'for': 'Payments for',
        'changeSuccess': 'Payment method has been successfully added.',
        'changeFailed': 'Can not change payment method, provided card data was not valid. Try again.',
        'datatransFailed': 'Datatrans Lightbox failed to load.',
        'popup': {
            'title': 'Change payment method',
            'sideText': 'Selected',
            'method': {
                'creditcard': 'Credit Card',
                'bill': 'Bill'
            }
        },
        'invoice': 'Invoice',
        'selected': 'Selected'
    },
    'summary': {
        'title': 'Summary',
        'month': 'month',
        'desc': 'The Newspaper from Monday to Saturday including unlimited digital access.',
        'total': 'Total',
        'after': 'After that',
        'year': 'year',
        'accept': {
            'partOne': 'By clicking on \'Submit order\' you accept our',
            't&c': 'terms and conditions',
            'and1': 'and',
            'and2': '',
            'dataProtection': 'data protection guidelines'
        },
        'submit': 'Submit order',
        'deliveryDate': 'Delivery date',
        'selectDate': 'Select delivery date',
        'discount': 'Discount',
        'shipping': 'Shipping Costs'
    },
    'button': {
        'change': 'Change',
        'cancel': 'Cancel',
        'apply': 'Apply'
    },
    'footer': {
        'phone': {
            'title': 'Do you have questions?',
            'subTitle': 'Call us, we will be happy to help you personally.'
        },
        'email': {
            'title': 'Contact us',
            'subTitle': 'Online contact form'
        },
        'SSL': {
            'title': 'Safe ordering process',
            'subTitle': {
                'partOne': 'Your details will be transmitted',
                'partTwo': 'with an SSL-encrypted certificate.'
            }
        }
    },
    'classic': 'Classic',
    'checkout': 'Checkout',
    'next': 'Next',
    'back': 'Back',
    'price': 'Price',
    'thankYou': {
        'title': 'Thank you',
        'postprocessing': 'Your order is currently in post processing. Once we have processed your order you will see it under my account and will be able to read online.',
        'subtitle': 'Your order was completed successfully.',
        'text': 'An email receipt including the details about your order has been sent to the email address provided. Please keep it for you records.',
        'myAccountBtn': 'Go to My Account',
        'startPageBtn': 'Go to',
        'bannerText': {
            'part1': 'Get more from the subscription and install the',
            'part2': 'app',
            'part3': ''
        },
        'bannerBtnText': {
            'version1': 'To App',
            'version2': ''
        }
    },
    'errorPage': {
        'text': 'something went wrong.',
        'button': 'Retry'
    },
    'notFoundPage': {
        'text': 'The page you requested was not found.'
    },
    'gift': 'gift',
    'campagin': 'campagin'
};
