import { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { BffServiceContext } from './BffServiceContext';
import { CheckoutContext } from './CheckoutContext';
import { OfferContext } from './OfferContext';
import { CustomerContext } from './CustomerContext';
import { OrderType, OrderWithPrecheckType } from '../types/types';
import { paymentModes, OrderPaymentMode } from '../services/apis/Bff/types';

interface OrderContextType {
    order: OrderWithPrecheckType;
    setOrder: (order: OrderType) => void;
    isOrderCreated: boolean;
    isOrderCompleted: boolean;
    setIsOrderCompleted: (isCompleted: boolean) => void;
    isPostprocessing: boolean;
    setIsPostprocessing: (isPostprocessing: boolean) => void;
    transactionCode: string;
    setTransactionCode: (code: string) => void;
    setIsRedirected: (isRedirected: boolean) => void;
}

const OrderContext = createContext<OrderContextType>({} as OrderContextType);

const OrderProvider = ({ children }: { children: JSX.Element }): JSX.Element =>
{
    const [order, setOrder]: [OrderWithPrecheckType, any] = useState<OrderWithPrecheckType>({
        code: 0,
        tamediaId: '',
        paymentMode: ''
    });
    const [ isRedirected, setIsRedirected ] = useState(false);
    const [ isOrderCompleted, setIsOrderCompleted ]: [boolean, any] = useState(false);
    const [ isPostprocessing, setIsPostprocessing ]: [boolean, any] = useState(false);
    const [ transactionCode, setTransactionCode ]: [string, any] = useState('');
    const { bffService } = useContext(BffServiceContext);
    const { offer, offerId, isGiftOffer, isUpgradeOffer, alternativeKavz } = useContext(OfferContext);
    const { customer } = useContext(CustomerContext);
    const [ step, setStep, stepContent, , isLastStep ]: any = useContext(CheckoutContext);
    const { push } = useHistory();
    const isOrderCreated = order.code !== 0;

    const redirectToAppropriateStep = (orderData: OrderType) =>
    {
        if (isUpgradeOffer) {
            setStep(stepContent.length);
        } else if (!isRedirected) {
            if (isGiftOffer) {
                setStep(1);
            } else if (orderData.deliveryAddress) {
                if (orderData.paymentMode) {
                    setStep(stepContent.length);
                } else {
                    setStep(stepContent.length - 1);
                }
            }
            setIsRedirected(true);
        }
    };

    useEffect(() =>
    {
        (async function fetchOrder()
        {
            if (bffService && offer) {
                let orderData = null;

                try {
                    const response = await bffService.getOrder(offerId, isGiftOffer, isUpgradeOffer);

                    orderData = response.data.data;
                    redirectToAppropriateStep(orderData);
                    setOrder(orderData);
                } catch {
                    push('/error');
                }

                if (isLastStep && customer && orderData) {
                    const paymentMethod = (orderData.paymentMode === paymentModes.invoice ?
                        paymentModes.order.invoice :
                        paymentModes.order.card) as OrderPaymentMode;
                    const { data: { data: precheckData }} = await bffService.orderPrecheck({
                        sapCustomerNumber: customer.sapId,
                        orderCode: String(orderData.code),
                        sapOfferKey: isGiftOffer ? offer.sapData.offerKeyGift : offer.sapData.offerKey,
                        campaignKey: offer.sapData.campaignKey,
                        kavz: alternativeKavz || offer.sapData.kavz,
                        paymentMethod,
                        validFrom: null
                    });

                    setOrder({
                        ...orderData,
                        precheck: precheckData
                    });
                }
            }
        })();
    }, [bffService, offer, step]);

    const orderValue = useMemo(() => (
        {
            order, setOrder, isOrderCreated, isOrderCompleted, setIsOrderCompleted,
            isPostprocessing, setIsPostprocessing, transactionCode, setTransactionCode, setIsRedirected
        }
    ), [order, isOrderCompleted, isPostprocessing]);

    return (
        <OrderContext.Provider value={orderValue}>{children}</OrderContext.Provider>
    );
};

export {
    OrderContext,
    OrderProvider
};
