import { useContext, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import { TenantContext } from '../../hooks/TenantContext';
import { RedirectionService } from '../../services/utils/Redirection/Redirection';
import { AuthenticationContext } from '../../hooks/AuthenticationContext';
import { OfferContext } from '../../hooks/OfferContext';

export const CallbackScene = (): null => {
    const { tenantState: [tenant] }: any = useContext(TenantContext);
    const { setAuth, setIsNewUser } = useContext(AuthenticationContext);
    const { isGiftOffer, isUpgradeOffer, isCampagin } = useContext(OfferContext);
    const history = useHistory();
    const { search } = useLocation();
    const { t } = useTranslation();
    const queryParams = queryString.parse(search);
    const isNewUser = (queryParams.idp === 'OneLog' && queryParams.new_user === 'yes') || new Cookies().get('gatewayNewUser') === 'yes';

    const setIdTokenCookie = async (): Promise<void> => {
        const redirection = new RedirectionService(tenant.id);
        const idToken = await redirection.getToken();
        const cookies = new Cookies();

        cookies.set('isLoggedIn', true, { path: '/', secure: true });
        setAuth(idToken);
    };

    const { offerId, subscriptionId, kavz }: { offerId: string; subscriptionId: string, kavz: string } = useParams();

    useEffect(() => {
        if (tenant.id) {
            setIdTokenCookie();
            setIsNewUser(isNewUser);
            if (offerId) {
                if (isGiftOffer) {
                    history.push(`/${t('gift')}/${offerId}`);
                } else if (isUpgradeOffer) {
                    history.push(`/${offerId}/${subscriptionId}`);
                } else if (isCampagin) {
                    history.push(`/${t('campagin')}/${offerId}/${kavz}`);
                } else {
                    history.push(`/${offerId}`);
                }
            } else {
                window.location.href = tenant.links.pianoLandingPage;
            }
        }
    }, [tenant.id]);

    return null;
};

