import axios, { AxiosInstance, AxiosResponse } from 'axios';
import * as BffServiceTypes from './types';
import { AddressType, AddressWithOrderCode, DeliveryAddress } from '../../../types/types';

export class BffService
{
    private tenantId: string;

    axiosInstance: AxiosInstance;

    constructor(tenantId: string)
    {
        this.tenantId = tenantId;
        const baseURL = this.getBaseUrl(tenantId);

        this.axiosInstance = axios.create({
            baseURL,
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0'
            },
            withCredentials: false
        });
    }

    private getBaseUrl(tenantId: string): string
    {
        const url = process.env.REACT_APP_API_BASE_URL || '';
        let baseUrl = '';

        if (url) {
            baseUrl = [url.split('.')[0], tenantId, url.split('.')[2]].join('.');
        }

        return baseUrl;
    }

    get tenant (): string
    {
        return this.tenantId;
    }

    public getCustomer = ():Promise<AxiosResponse<BffServiceTypes.Customer>> => (
        this.axiosInstance.get<BffServiceTypes.Customer>('/customer')
    );

    public getOffer = (offerId: string): Promise<AxiosResponse<BffServiceTypes.GetOfferResponse>> => (
        this.axiosInstance.get<BffServiceTypes.GetOfferResponse>(`/offer/${offerId}`)
    );

    public createCustomer = (orderCode: number, payload: AddressType):
        Promise<AxiosResponse<BffServiceTypes.BasicMessageResponseType>> => (
        this.axiosInstance.post<BffServiceTypes.BasicMessageResponseType>(`/customer/checkout/${orderCode}`, payload)
    );

    public getTransactionId = (
        payload: BffServiceTypes.InitializePaymentPayload
    ): Promise<AxiosResponse<BffServiceTypes.InitializePaymentResponse>> => (
        this.axiosInstance.post<BffServiceTypes.InitializePaymentResponse>('/payment/init', payload)
    );

    public checkTransactionStatus = (transactionId: string): Promise<AxiosResponse<BffServiceTypes.Customer>> => (
        this.axiosInstance.get<BffServiceTypes.Customer>(`/payment/transactions/${transactionId}/status`)
    );

    public getOrder = (
        offerCode: string, isGiftOffer: boolean, isUpgradeOffer: boolean
    ):Promise<AxiosResponse<BffServiceTypes.Order>> =>
    {
        let url = `/order/normal/${offerCode}`;

        if (isGiftOffer) {
            url = `/order/gift/${offerCode}`;
        }
        if (isUpgradeOffer) {
            url = `/order/upgrade/${offerCode}`;
        }

        return this.axiosInstance.get<BffServiceTypes.Order>(url);
    };

    public getAddress = (addressType: string):Promise<AxiosResponse<BffServiceTypes.Address>> => (
        this.axiosInstance.get<BffServiceTypes.Address>('/address', { params: { type: addressType }})
    );

    public updateAddress = (payload: AddressWithOrderCode):Promise<AxiosResponse<BffServiceTypes.BasicMessageResponseType>> => (
        this.axiosInstance.put<BffServiceTypes.BasicMessageResponseType>('/address', payload)
    );

    public updateOrderPaymentMode = (
        orderCode: number, paymentMode: BffServiceTypes.PaymentMode
    ):Promise<AxiosResponse<BffServiceTypes.Order>> => (
        this.axiosInstance.post<BffServiceTypes.Order>(`/order/${orderCode}/payment`, { paymentMode })
    );

    public orderPrecheck = (
        payload: BffServiceTypes.OrderPayload
    ):Promise<AxiosResponse<BffServiceTypes.OrderPrecheckResponse>> => (
        this.axiosInstance.post<BffServiceTypes.OrderPrecheckResponse>('/order/precheck', payload)
    );

    public getReleaseDates = ():Promise<AxiosResponse<BffServiceTypes.ReleaseDatesResponse>> => (
        this.axiosInstance.get<BffServiceTypes.ReleaseDatesResponse>('/releaseDates/checkout')
    );

    public createOrder = (payload: BffServiceTypes.OrderPayload):Promise<AxiosResponse<any>> => (
        this.axiosInstance.post<any>('/order', payload)
    );

    public getCountries = ():Promise<AxiosResponse<BffServiceTypes.CountriesResponse>> => (
        this.axiosInstance.get<any>('/countries')
    );

    public authorizePayment = (
        payload: BffServiceTypes.AuthorizePaymentPayload
    ): Promise<AxiosResponse<BffServiceTypes.InitializePaymentResponse>> => (
        this.axiosInstance.post<BffServiceTypes.InitializePaymentResponse>('/payment/authorize', payload)
    );

    public getSubscriptions = ():Promise<AxiosResponse<BffServiceTypes.GetSubscriptionsResponse>> => (
        this.axiosInstance.get<any>('/subscriptions')
    );

    public getAddressAssembly = (address: DeliveryAddress):Promise<AxiosResponse<BffServiceTypes.GetAddressAssemblyResponse>> => (
        this.axiosInstance.post<BffServiceTypes.GetAddressAssemblyResponse>('/address/assembly', { address })
    );
}
