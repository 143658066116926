import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Lightbox from 'react-datatrans-light-box';
import { Typography } from '@tampmd/hyperion-ui/Typography';
import { Paper } from '@tampmd/hyperion-ui/Paper';
import { PaymentCard, VariantType } from '@tampmd/hyperion-ui/PaymentCard';
import { Divider } from '@tampmd/hyperion-ui/Divider';
import { CustomerContext } from '../../hooks/CustomerContext';
import { BffServiceContext } from '../../hooks/BffServiceContext';
import { NotificationContext } from '../../hooks/NotificationContext';
import { CheckoutContext } from '../../hooks/CheckoutContext';
import { OfferContext } from '../../hooks/OfferContext';
import { OrderContext } from '../../hooks/OrderContext';
import { PaymentContext } from '../../hooks/PaymentContext';
import { PaymentMethodSymbol, paymentModes } from '../../services/apis/Bff/types';
import { InvoiceCard } from './components/InvoiceCard';

const getCardNameCapitalized = (cardName: string) => `${cardName.charAt(0).toUpperCase()}${cardName.slice(1)}`;

const getCardVariant = (cardName: string) => (cardName === 'master' ? 'mastercard' : cardName);

export const PaymentComponent = (): JSX.Element =>
{
    const { customer } = useContext(CustomerContext);
    const { payment } = useContext(PaymentContext);
    const [ , setStep, stepContent ]: any = useContext(CheckoutContext);
    const { isUpgradeOffer } = useContext(OfferContext);
    const { t } = useTranslation();
    const isInvoicePayment = payment === paymentModes.invoice;

    return (
        <>
            <Divider/>
            <Paper classes={['my-3', 'd-flex', 'align-items-baseline', 'justify-content-between']}>
                <Paper classes={['d-inline']}>
                    {
                        (customer.payment && !isInvoicePayment) &&
                            <>
                                <PaymentCard variant={
                                    getCardVariant(customer.payment.type.toLowerCase()) as VariantType
                                }
                                classes={['mr-2']}
                                />
                                <Typography variant="text" autoWidth inline>
                                    {getCardNameCapitalized(customer.payment.type.toLowerCase())}
                                    {' '}
                                    {customer.payment.maskedNumber}
                                </Typography>
                            </>
                    }
                    {
                        isInvoicePayment &&
                            <>
                                <InvoiceCard/>
                                <Typography variant="text" autoWidth inline>
                                    {t('payment.invoice')}
                                </Typography>
                            </>
                    }
                </Paper>
                {
                    !isUpgradeOffer
                    && (
                        <Typography variant="infotext" underline autoWidth inline
                            onClick={() => setStep(stepContent.length - 1)}>
                            {t('button.change')}
                        </Typography>
                    )
                }
            </Paper>
            <Divider/>
        </>
    );
};

export const Payment = (): JSX.Element =>
{
    const [ transactionId, setTransactionId ] = useState<string>('');
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const { customer } = useContext(CustomerContext);
    const { bffService } = useContext(BffServiceContext);
    const { setNotification } = useContext(NotificationContext);
    const { offer } = useContext(OfferContext);
    const { order } = useContext(OrderContext);
    const { payment, setPayment } = useContext(PaymentContext);
    const { t } = useTranslation();

    const getTransactionId = async (paymentMethod: PaymentMethodSymbol) =>
    {
        if (bffService) {
            try {
                const { location } = window;

                setIsLoading(true);
                const response = await bffService?.getTransactionId({
                    redirect: {
                        successUrl: `${location.href}?status=success`,
                        cancelUrl: location.href,
                        errorUrl: `${location.href}?status=error`
                    },
                    ...(paymentMethod && { paymentMethod }),
                    ...(order.code && { orderCode: String(order.code) })
                });

                setTransactionId(response.data.data.transactionId);
            } catch (error: any) {
                setNotification({
                    status: 'danger',
                    description: error.response.data.data.message
                });
            } finally {
                setIsLoading(false);
            }
        }
    };

    const changePaymentMethod = async (datatransSymbol: PaymentMethodSymbol | null, isSelected: boolean) =>
    {
        if (datatransSymbol && !isSelected) {
            !isLoading && await getTransactionId(datatransSymbol);
        } else if (datatransSymbol && isSelected) {
            setPayment(mapSymbolToVariant(datatransSymbol));
        } else {
            setPayment(paymentModes.invoice);
        }
    };

    const mapSymbolToVariant = (abbr: PaymentMethodSymbol | undefined) =>
    {
        switch (abbr) {
        case 'VIS':
            return 'visa';
        case 'ECA':
            return 'master';
        case 'PFC':
            return 'postfinance';
        case 'TWI':
            return 'twint';
        default:
            return '';
        }
    };

    const onError = () => setNotification({
        status: 'danger',
        description: `${t('payment.datatransFailed')}`
    });

    const onCancelled = () => setTransactionId('');

    const renderCard = (datatransSymbol: PaymentMethodSymbol, cardType: string): JSX.Element => (
        <>
            {
                datatransSymbol ?
                    <PaymentCard
                        variant={getCardVariant(cardType) as VariantType}
                        classes={['mr-2']}
                    /> :
                    <InvoiceCard/>
            }
        </>
    );

    const renderCardInfo = (
        props: {
            isSelected: boolean;
            cardType: string;
            isInvoicePayment: boolean;
            datatransSymbol: PaymentMethodSymbol;
        }
    ): JSX.Element => (
        <>
            {
                (customer.payment && props.isSelected) ?
                    <Typography variant="text" autoWidth inline
                        classes={[!props.isInvoicePayment ? 'font-weight-bold' : '']}>
                        { getCardNameCapitalized(customer.payment.type.toLowerCase()) }
                        { ' ' }
                        {customer.payment.maskedNumber}
                    </Typography> :
                    <Typography variant="text" autoWidth inline
                        classes={[(props.isInvoicePayment && !props.datatransSymbol) ? 'font-weight-bold' : '']}>
                        { getCardNameCapitalized(props.cardType) || t('payment.invoice') }
                    </Typography>
            }
        </>
    );

    return (
        <Paper id="checkout-payment">
            {
                offer && offer.paymentMethods.map((paymentMethod, index) =>
                {
                    const datatransSymbol = paymentMethod.datatransSymbol as PaymentMethodSymbol;
                    const customerPayment = customer.payment && customer.payment.type.toLowerCase();
                    const cardType = mapSymbolToVariant(datatransSymbol);
                    const currentPayment = datatransSymbol && cardType;
                    const isSelected = typeof customerPayment !== 'undefined' && customerPayment === currentPayment;
                    const isFirstElement = index === 0;
                    const isInvoicePayment = payment === paymentModes.invoice;

                    return (
                        <Paper key={paymentMethod.code}
                            classes={['checkout-payment__payment-method']}
                            onClick={() => changePaymentMethod(datatransSymbol || null, isSelected)}
                        >
                            { isFirstElement && <Divider/> }
                            <Paper classes={['my-3', 'd-flex', 'align-items-baseline', 'justify-content-between']}>
                                <Paper classes={['d-inline']}>
                                    {renderCard(datatransSymbol, cardType)}
                                    {renderCardInfo({
                                        datatransSymbol,
                                        isSelected,
                                        isInvoicePayment,
                                        cardType
                                    })}
                                </Paper>
                                {
                                    (
                                        (isInvoicePayment && !datatransSymbol) ||
                                        (!isInvoicePayment && isSelected)
                                    ) &&
                                    <Typography variant="infotext" underline autoWidth inline>
                                        {t('payment.selected')}
                                    </Typography>
                                }
                            </Paper>
                            <Divider/>
                        </Paper>
                    );
                })
            }
            {
                transactionId &&
                <Lightbox
                    transactionId={transactionId}
                    production={process.env.REACT_APP_DATATRANS_ENV === 'prod'}
                    onCancelled={onCancelled}
                    onError={onError}/>
            }
        </Paper>
    );
};
