import { TenantProvider } from '../../hooks/TenantContext';
import { CheckoutProvider } from '../../hooks/CheckoutContext';
import { PopupProvider } from '../../hooks/PopupContext';
import { FormProvider } from '../../hooks/FormContext';
import { OfferProvider } from '../../hooks/OfferContext';
import { BffServiceProvider } from '../../hooks/BffServiceContext';
import { AuthenticationProvider } from '../../hooks/AuthenticationContext';
import { CustomerProvider } from '../../hooks/CustomerContext';
import { NotificationProvider } from '../../hooks/NotificationContext';
import { OrderProvider } from '../../hooks/OrderContext';
import { DeliveryDateProvider } from '../../hooks/DeliveryDateContext';
import { PaymentProvider } from '../../hooks/PaymentContext';

export const ContextProviders = ({ children }: { children: JSX.Element[] }): JSX.Element => (
    <TenantProvider>
        <AuthenticationProvider>
            <BffServiceProvider>
                <CustomerProvider>
                    <CheckoutProvider>
                        <OfferProvider>
                            <OrderProvider>
                                <PopupProvider>
                                    <FormProvider>
                                        <NotificationProvider>
                                            <DeliveryDateProvider>
                                                <PaymentProvider>
                                                    {children}
                                                </PaymentProvider>
                                            </DeliveryDateProvider>
                                        </NotificationProvider>
                                    </FormProvider>
                                </PopupProvider>
                            </OrderProvider>
                        </OfferProvider>
                    </CheckoutProvider>
                </CustomerProvider>
            </BffServiceProvider>
        </AuthenticationProvider>
    </TenantProvider>
);
