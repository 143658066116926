import { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Address } from '../Address/Address';
import { PaymentComponent } from '../Payment/Payment';
import { Summary } from '../Summary/Summary';
import { StepHeader } from './components/StepHeader';
import { BffServiceContext } from '../../hooks/BffServiceContext';
import { OrderContext } from '../../hooks/OrderContext';
import { OfferContext } from '../../hooks/OfferContext';
import { TenantContext } from '../../hooks/TenantContext';
import { NotificationContext } from '../../hooks/NotificationContext';
import { PaymentContext } from '../../hooks/PaymentContext';
import { PaymentMode } from '../../services/apis/Bff/types';
import * as GoogleAnalytics from '../../services/GoogleAnalytics/GoogleAnalytics';

export const CheckoutStep = (): JSX.Element =>
{
    const { t } = useTranslation();
    const { bffService } = useContext(BffServiceContext);
    const { order } = useContext(OrderContext);
    const { setNotification } = useContext(NotificationContext);
    const { payment } = useContext(PaymentContext);
    const { offer }: any = useContext(OfferContext);
    const { tenantState: [ tenant ] }: any = useContext(TenantContext);

    useEffect(() =>
    {
        const email = order.paymentAddress?.ispemail;

        payment && GoogleAnalytics.pushCheckoutDataLayer({
            step: 3, offer, tenantId: tenant.id, option: payment, email
        });

        (async function updatePaymentMode()
        {
            try {
                if (bffService && order.code && payment && order.paymentMode !== payment) {
                    await bffService.updateOrderPaymentMode(order.code, payment as PaymentMode);
                }
            } catch (error: any) {
                const { response: { data: errorResponse }} = error;
                const { message } = errorResponse.error || errorResponse.data;

                setNotification({
                    status: 'danger',
                    description: message
                });
            }
        })();
    }, [payment]);

    return (
        <>
            <StepHeader title={t('checkout')}/>
            <Address/>
            <PaymentComponent/>
            <Summary/>
        </>
    );
};
