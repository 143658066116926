import { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { BffServiceContext } from './BffServiceContext';
import { CustomerType } from '../types/types';

interface CustomerContextType {
    customer: CustomerType;
    setCustomer: (customer: CustomerType) => void;
    isCustomerLinked: boolean;
}

const CustomerContext = createContext<CustomerContextType>({} as CustomerContextType);

const CustomerProvider = ({ children }: { children: JSX.Element }): JSX.Element =>
{
    const [customer, setCustomer]: [CustomerType, any] = useState({
        tamediaId: '',
        sapId: ''
    });
    const { bffService } = useContext(BffServiceContext);
    const isCustomerLinked = Boolean(customer.sapId);

    useEffect(() =>
    {
        (async function fetchCustomer()
        {
            if (bffService && !customer.tamediaId) {
                const { data: { data: customer }} = await bffService.getCustomer();

                setCustomer(customer);
            }
        })();
    }, [bffService]);

    const customerValue = useMemo(() => (
        { customer, setCustomer, isCustomerLinked }
    ), [customer]);

    return (
        <CustomerContext.Provider value={customerValue}>{children}</CustomerContext.Provider>
    );
};

export {
    CustomerContext,
    CustomerProvider
};
