import Joi from 'joi';

export const validateField = (field: string, value: string, message: string): Joi.ValidationResult =>
{
    let schema = Joi.object({});

    switch (field) {
    case 'zipCode':
        schema = Joi.object({ [field]: Joi.string().max(4).allow('')
            .messages({
                'string.max': message
            })
        });
        break;
    case 'addressCompletion':
        schema = Joi.object({ [field]: Joi.string().max(35).allow('')
            .messages({
                'string.max': message
            })
        });
        break;
    default:
        schema = Joi.object({});
    }

    return schema.validate({ [field]: value });
};

